import React, { useState, useEffect, useRef } from 'react';
import swal from 'sweetalert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import { updateToken } from '../../Utils';
import Table from 'react-bootstrap/Table';
import Navbar from '../../components/Navigation';
import Header from '../../components/Header';
import Spinner from '../../components/Spinner';
import Container from 'react-bootstrap/Container';
import Pagination from 'react-bootstrap/Pagination';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import { deleteCarrierById, getAllCarriers } from '../../services/index';

const CarrierList = (props) => {
  const take = 25;

  const timeoutRef = useRef(null);
  const [skip, setSkip] = useState(0);
  const [timeout, updateTimeout] = useState(0);
  const [carriers, setCarriers] = useState([]);
  const [loading, showSpinner] = useState(false);
  const [keywordFilter, setKeyword] = useState('');

  useEffect(() => {
    if (timeoutRef.current !== null) clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      timeoutRef.current = null;
      getCarriers();
    }, timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keywordFilter, skip]);


  const getCarriers = async () => {
    showSpinner(true);
    const url = `carrier?keyword=${keywordFilter.toLowerCase().trim()}&take=${take}&skip=${skip}`;
    const response = await getAllCarriers(url);
    if (response) {
      if (response.status === 200 || response.status === 201) {
        setCarriers(response?.data);
        showSpinner(false);
        updateTimeout(1500);
      } else {
        if (response.response.status === 401 || response.response.data === 'Unauthorized') {
          await updateToken();
        }
        if (response.response.status >= 500) {
          toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
        }
        showSpinner(false);
      }
    }
  };

  const handleDelete = async (carrier) => {
    swal({
      title: `Delete ${carrier.name}`,
      text: `Are you sure that you want to delete ${carrier.name}?`,
      buttons: ['Cancel', 'Yes'],
      icon: 'error',
      dangerMode: true,
    }).then(async (status) => {
      if (status) {
        const response = await deleteCarrierById(carrier.id);
        if (response) {
          if (response.status === 200 || response.status === 201) {
            toast.success(`${carrier.name} has been deleted.`);
            getCarriers();
          } else {
            if (response.response.status === 401 || response.response.data === 'Unauthorized') {
              await updateToken();
            }
            if (response.response.status >= 500) {
              toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
            }
          }
        }
      }
    });
  };

  const handlePrevious = () => {
    setSkip(skip - take);
  };

  const handleNext = () => {
    setSkip(skip + take);
  };

  return (
    <>
      <Navbar />
      <div className='main-content'>
        <Header title='Phox Health' name='Couriers'>
          <Link to='/carrier/create' className='btn btn-white'>
            Add Courier
          </Link>
        </Header>
        <Container>
          <Card>
            <Card.Header>
              <Row>
                <Col>
                  <InputGroup className='input-group-flush'>
                    <InputGroup.Prepend>
                      <InputGroup.Text>
                        <i className='fe fe-search'></i>
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl placeholder='Search by name' value={keywordFilter} onChange={(e) => setKeyword(e.target.value)} />
                  </InputGroup>
                </Col>
              </Row>
            </Card.Header>

            <Spinner display={loading}>
              <Table responsive size='sm'>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Location</th>
                    <th></th>
                  </tr>
                </thead>
                {carriers &&
                  carriers.map((carrier, index) => {
                    return (
                      <tbody key={index}>
                        <tr>
                          <td>{carrier.name}</td>
                          <td>
                            {carrier.address.city}, {carrier.address.state}
                          </td>
                          <td className='text-right'>
                            <span className='dropdown'>
                              <a href='/#' className='dropdown-ellipses dropdown-toggle' role='button' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                                <i className='fe fe-more-vertical'></i>
                              </a>
                              <div className='dropdown-menu dropdown-menu-right'>
                                <Link className='dropdown-item' to={`carrier/${carrier.id}/dashboard`}>
                                  View
                                </Link>
                                <Link className='dropdown-item' to={`carrier/${carrier.id}/edit`}>
                                  Edit
                                </Link>
                                <button
                                  className='dropdown-item'
                                  onClick={() => {
                                    handleDelete(carrier);
                                  }}
                                >
                                  Delete
                                </button>
                              </div>
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    );
                  })}
              </Table>
              <Pagination size='sm' className='justify-content-center'>
                <Pagination.Item
                  disabled={skip === 0}
                  onClick={() => {
                    handlePrevious();
                  }}
                >
                  Previous
                </Pagination.Item>
                <Pagination.Item disabled={true}>Page {skip / take + 1}</Pagination.Item>
                <Pagination.Item
                  disabled={carriers.length < take}
                  onClick={() => {
                    handleNext();
                  }}
                >
                  Next
                </Pagination.Item>
              </Pagination>
            </Spinner>
          </Card>
        </Container>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return { user: state.user };
};
export default connect(mapStateToProps)(CarrierList);
