import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import swal from 'sweetalert';
import { Formik } from 'formik';
import { sortBy, filter } from 'lodash';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { updateToken } from '../../Utils';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Navbar from '../../components/Navigation';
import Header from '../../components/Header';
import Spinner from '../../components/Spinner';
import Container from 'react-bootstrap/Container';
import { SelectField } from '../../components/SelectField';
import { inviteUsers, getShippersByGroup, getShippersById, getUserByEmail, updateUserPassword, updateUserById, deleteShipperUserById, getShippersUsersById, createUser, sendInvitationLink } from '../../services';

function ShipperUsers(props) {
  const inviteUserInitialData = {
    name: '',
    email: '',
    phone: '',
    shipper: null,
    title: '',
    role: 'viewer',
  };

  const inviteUserSchema = Yup.object().shape({
    name: Yup.string().required('Name is required.'),
    email: Yup.string().email('Email is invalid.').required('Email is required.'),
    shipper: Yup.array()
      .min(1, 'Select at least one Shipper')
      .of(
        Yup.object()
          .shape({
            label: Yup.string(),
            value: Yup.string(),
          })
          .required('Shipper is required')
      ),
  });

  const editUserSchema = Yup.object().shape({
    name: Yup.string().required('Name is required.'),
  });

  const shipperId = props.match.params.id;
  const [user, setUser] = useState({});
  const [users, setUsers] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [shipper, setShipper] = useState({});
  const [loading, showSpinner] = useState(false);
  const [shipperOptions, setShipperOptions] = useState([]);
  const [filteredUsers, setfilteredUsers] = useState(false);
  const [showInviteModal, setInviteModal] = useState(false);
  const [modalEditUser, toggleEditUserModal] = useState(false);
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const [inviteUser, setInviteUser] = useState(inviteUserInitialData);

  useEffect(() => {
    getShipper();
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getShipper = async () => {
    showSpinner(true);
    const response = await getShippersById(shipperId);
    if (response) {
      if (response.status === 200 || response.status === 201) {
        const shipper = response?.data;
        setShipper(response?.data);
        setInviteUser({ ...inviteUser, shipper: [{ value: shipper?.id, label: shipper?.name }] });
        await getShippersOptions(shipper);
        await showSpinner(false);
      } else {
        if (response.response.status === 401 || response.response.data === 'Unauthorized') {
          await updateToken();
        }
        if (response.response.status >= 500) {
          toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
        }
        showSpinner(false);
      }
    }
  };

  const getUsers = async () => {
    showSpinner(true);
    const response = await getShippersUsersById(shipperId);
    if (response) {
      if (response.status === 200 || response.status === 201) {
        let userData = response?.data;
        userData.map((user) => {
          user.name = user.name?.toLowerCase() || "";
          return user;
        });
        userData = sortBy(response?.data, 'name');
        setUsers(userData);
        showSpinner(false);
      } else {
        if (response.response.status === 401 || response.response.data === 'Unauthorized') {
          await updateToken();
        }
        if (response.response.status >= 500) {
          toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
        }
        showSpinner(false);
      }
    }
  };

  const getShippersOptions = async (shipper) => {
    showSpinner(true);
    const response = await getShippersByGroup(shipper?.group_name?.toLowerCase());
    if (response) {
      if (response.status === 200 || response.status === 201) {
        const shippers = response?.data;
        let shipperList = [];
        shippers.map((item) => {
          return shipperList.push({ value: item?.id, label: item?.name });
        });
        setShipperOptions(shipperList);
        showSpinner(false);
      } else {
        if (response.response.status >= 500) {
          toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
        }
        showSpinner(false);
      }
    }
  };

  const onSearch = ({ target: { value } }) => {
    const search = value.toLowerCase().replace(/\s/g, '').trim();
    setKeyword(value);
    const filteredUsers = !value
      ? false
      : filter(users, (item) => {
        return item.name.toLowerCase().replace(/\s/g, '').trim().includes(search.toLowerCase().replace(/\s/g, '').trim()) || item.email.toLowerCase().replace(/\s/g, '').trim().includes(search.toLowerCase().replace(/\s/g, '').trim());
      });
    setfilteredUsers(filteredUsers);
  };

  const inviteModal = () => {
    setInviteModal(true);
  };

  const hideInviteModal = () => {
    setInviteModal(false);
  };

  const handleInviteUser = async (user) => {
    const fixedUserData = { ...user };
    user.name = user.name.trim();
    user.email = user.email.toLowerCase().trim();
    delete user.shipper;
    let shipperIds = [];
    fixedUserData.shipper.map((item) => {
      return shipperIds.push(item.value);
    });
    hideInviteModal();
    showSpinner(true);
    const response = await getUserByEmail(user.email.toLowerCase().trim());
    if (response) {
      if (response.status === 200 || response.status === 201) {
        const dbUser = response?.data;
        const request = { role: user.role };
        const result = await inviteUsers(request, dbUser.id, shipperIds);
        if (result) {
          getUsers();
          toast.success(`${user.name}'s details has been updated`);
        }
      } else {
        if (response && response.response.status === 404) {
          try {
            const response = await createUser(user);
            if ((response && response.status === 201) || response.status === 200) {
              const dbUser = response?.data;
              const request = { role: user.role };
              const result = await inviteUsers(request, dbUser.id, shipperIds);
              if (result) {
                getUsers();
                toast.success(`${user.name} has been invited.`);
              }
            }
          } catch (err) {
            console.log(err);
          }
        } else if (response.response.status === 401 || response.response.data === 'Unauthorized') {
          await updateToken();
        } else if (response.response.status >= 500) {
          toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
        }
        showSpinner(false);
      }
    }
  };

  const editUserModal = async (user) => {
    user.role = user.roles.shipper[shipperId];
    setUser(user);
    setShowEditUserModal(true);
  };

  const hideEditUserModal = () => {
    setUser({});
    setShowEditUserModal(false);
  };

  const handleEditUser = async (user) => {
    if (user.role) {
      hideEditUserModal();
      showSpinner(true);
      const request = {
        role: user?.role,
        name: user?.name?.trim(),
        title: user?.title,
        phone: user?.phone,
      };
      const response = await updateUserById(shipperId, user.id, request);
      if (response) {
        if (response.status === 200 || response.status === 201) {
          getUsers();
          showSpinner(false);
          toast.success(`${user.name}'s details has been updated.`);
        } else {
          if (response.response.status === 401 || response.response.data === 'Unauthorized') {
            await updateToken();
          }
          if (response.response.status >= 500) {
            toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
          }
          showSpinner(false);
        }
      }
    }
  };

  const handleRemove = async (user) => {
    swal({
      title: `Remove ${user.name}`,
      text: `Are you sure that you want to remove ${user.name}?`,
      buttons: ['Cancel', 'Yes'],
      icon: 'error',
      dangerMode: true,
    }).then(async (status) => {
      if (status) {
        showSpinner(true);
        const response = await deleteShipperUserById(shipperId, user.id);
        if (response) {
          if (response.status === 200 || response.status === 201) {
            getUsers();
            toast.success(`${user.name} has been removed.`);
            showSpinner(false);
          } else {
            if (response.response.status === 401 || response.response.data === 'Unauthorized') {
              await updateToken();
            }
            if (response.response.status >= 500) {
              toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
            }
            showSpinner(false);
          }
        }
      }
    });
  };

  const handleInvite = async (user) => {
    swal({
      title: `Resend Invitation`,
      text: `Are you sure that you want to resend the invite to ${user.name}?`,
      buttons: ['Cancel', 'Yes'],
      icon: 'warning',
      dangerMode: true,
    }).then(async (status) => {
      if (status) {
        showSpinner(true);
        const request = {
          name: user?.name
        }
        const response = await sendInvitationLink(shipperId, user.id, request);
        if (response) {
          if (response.status === 200 || response.status === 201) {
            getUsers();
            toast.success(`Invite has been sent to ${user.name}.`);
            showSpinner(false);
          } else {
            if (response.response.status === 401 || response.response.data === 'Unauthorized') {
              await updateToken();
            }
            if (response.response.status >= 500) {
              toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
            }
            showSpinner(false);
          }
        }
      }
    });
  };


  const showResetPasswordModal = async (user) => {
    user.password = '';
    setUser(user);
    toggleEditUserModal(true);
  };

  const handleResetPassword = async (user) => {
    const request = {
      password: user.password,
    };
    const response = await updateUserPassword(user.id, request);
    if (response) {
      if (response.status === 200 || response.status === 201) {
        toast.success(`${user.name}'s password has been updated.`);
      } else {
        if (response.response.status === 401 || response.response.data === 'Unauthorized') {
          await updateToken();
        }
        if (response.response.status >= 500) {
          toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
        }
      }
    }
    toggleEditUserModal(false);
  };

  const usersList = filteredUsers ? filteredUsers : users;

  return (
    <>
      <Navbar />
      <div className="main-content">
        <Header title={shipper.name} name='Users'>
          <Button variant='white' onClick={inviteModal}>
            Invite User
          </Button>
        </Header>

        <Container>
          <Card>
            <Card.Header>
              <form>
                <div className='input-group input-group-flush'>
                  <div className='input-group-prepend'>
                    <span className='input-group-text'>
                      <i className='fa fa-search'></i>
                    </span>
                  </div>
                  <input className='list-search form-control' type='search' placeholder='Search by name, email' id='keyword' value={keyword} name='keyword' onChange={onSearch} />
                </div>
              </form>
            </Card.Header>
            <Spinner display={loading}>
              <Table responsive size='sm'>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Phone</th>
                    <th>Title / Company</th>
                    <th className='text-center'>Role</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {usersList &&
                    usersList.map((user, index) => {
                      return (
                        <tr key={index}>
                          <td >
                            <>
                              <h5 className="text-capitalize">{user.name}</h5>
                              <div className="small text-muted">{user.email}</div>
                            </>
                          </td>
                          <td>{user.phone}</td>
                          <td>
                            {user.title}
                            <div className='small text-muted'>{user.company}</div>
                          </td>
                          <td className='text-center'>
                            {user.roles.shipper[shipperId] === 'viewer' && <span className='badge badge-primary'>Viewer</span>}
                            {user.roles.shipper[shipperId] === 'editor' && <span className='badge badge-warning'>Editor</span>}
                            {user.roles.shipper[shipperId] === 'owner' && <span className='badge badge-danger'>Owner</span>}
                          </td>
                          <td className='text-right'>
                            <div className='dropdown'>
                              <a href='/#' className='dropdown-ellipses dropdown-toggle' role='button' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                                <i className='fe fe-more-vertical'></i>
                              </a>
                              <div className='dropdown-menu dropdown-menu-right'>
                                <button
                                  className='dropdown-item'
                                  onClick={() => {
                                    editUserModal(user);
                                  }}
                                >
                                  Edit
                                </button>
                                <button
                                  className='dropdown-item'
                                  onClick={() => {
                                    handleInvite(user);
                                  }}
                                >
                                  Resend Invite
                                </button>
                                <button
                                  className='dropdown-item'
                                  onClick={() => {
                                    showResetPasswordModal(user);
                                  }}
                                >
                                  Reset Password
                                </button>
                                <button
                                  className='dropdown-item'
                                  onClick={() => {
                                    handleRemove(user);
                                  }}
                                >
                                  Remove
                                </button>
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </Spinner>
          </Card>
        </Container>

        <Modal show={showInviteModal} onHide={hideInviteModal}>
          <Modal.Body>
            <Modal.Title className='h1'>Invite User</Modal.Title>
            <hr />

            <Formik initialValues={inviteUser} validationSchema={inviteUserSchema} onSubmit={handleInviteUser}>
              {({ handleChange, handleSubmit, setFieldValue, values, errors }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Form.Group>
                    <Form.Label>Name</Form.Label>
                    <Form.Control type='text' name='name' placeholder='e.g. John Doe' value={values.name} onChange={handleChange} isInvalid={!!errors.name} />
                    <Form.Control.Feedback type='invalid'>{errors.name}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Email</Form.Label>
                    <Form.Control type='email' name='email' placeholder='e.g. john@phoxhealth.com' value={values.email} onChange={handleChange} isInvalid={!!errors.email} />
                    <Form.Control.Feedback type='invalid'>{errors.email}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>
                      Shippers<span className='text-danger'>*</span>
                    </Form.Label>
                    <SelectField required error={errors.shipper} id='shipper' name='shipper' placeholder='Select' options={shipperOptions} value={values.shipper} isMulti={true} onChange={setFieldValue} isClearable={true} backspaceRemovesValue={true} />
                    <Form.Control.Feedback type='invalid'>{errors.shipper}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Phone</Form.Label>
                    <Form.Control type='phone' name='phone' maxLength='10' placeholder='e.g. +1 983 331 3464' value={values.phone} onChange={handleChange} />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Title</Form.Label>
                    <Form.Control type='text' name='title' placeholder='e.g. Pharmacist' value={values.title} onChange={handleChange} isInvalid={!!errors.title} />
                    <Form.Control.Feedback type='invalid'>{errors.title}</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>Role</Form.Label>
                    <Form.Control as='select' name='role' custom value={values.role} onChange={handleChange}>
                      <option value='viewer'>Viewer</option>
                      <option value='editor'>Editor</option>
                      <option value='owner'>Owner</option>
                    </Form.Control>
                  </Form.Group>

                  <Button type='submit' variant='primary'>
                    Invite User
                  </Button>
                  <Button variant='outline-secondary' onClick={hideInviteModal} className='ml-2'>
                    Cancel
                  </Button>
                </Form>
              )}
            </Formik>
          </Modal.Body>
        </Modal>

        <Modal show={showEditUserModal} onHide={hideEditUserModal}>
          <Modal.Body>
            <Modal.Title className='h1'>Edit User</Modal.Title>
            <hr />
            <Formik initialValues={user} validationSchema={editUserSchema} onSubmit={handleEditUser}>
              {({ handleChange, handleSubmit, values, errors }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Form.Group>
                    <Form.Label>Name</Form.Label>
                    <Form.Control type='text' name='name' placeholder='e.g. John Doe' value={values.name} onChange={handleChange} isInvalid={!!errors.name} />
                    <Form.Control.Feedback type='invalid'>{errors.name}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Phone</Form.Label>
                    <Form.Control type='phone' name='phone' maxLength='10' placeholder='e.g. +1 983 331 3464' value={values.phone} onChange={handleChange} />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Title</Form.Label>
                    <Form.Control type='text' name='title' placeholder='e.g. Pharmacist' value={values.title} onChange={handleChange} isInvalid={!!errors.title} />
                    <Form.Control.Feedback type='invalid'>{errors.title}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Role</Form.Label>
                    <Form.Control as='select' name='role' custom value={values.role} onChange={handleChange}>
                      <option value='viewer'>Viewer</option>
                      <option value='editor'>Editor</option>
                      <option value='owner'>Owner</option>
                    </Form.Control>
                  </Form.Group>
                  <Button type='submit' variant='primary'>
                    Update
                  </Button>
                  <Button variant='outline-secondary' onClick={hideEditUserModal} className='ml-2'>
                    Cancel
                  </Button>
                </Form>
              )}
            </Formik>
          </Modal.Body>
        </Modal>

        <Modal
          show={modalEditUser}
          onHide={() => {
            toggleEditUserModal(false);
          }}
        >
          <Modal.Body>
            <Modal.Title className='h1'>Reset Password</Modal.Title>
            <hr />

            <Formik initialValues={user} validationSchema={inviteUserSchema} onSubmit={handleResetPassword}>
              {({ handleChange, handleSubmit, values, errors }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Form.Group>
                    <Form.Control type='text' name='password' value={values.password} onChange={handleChange} isInvalid={!!errors.password} />
                    <Form.Control.Feedback type='invalid'>{errors.password}</Form.Control.Feedback>
                  </Form.Group>

                  <Button type='submit' variant='primary'>
                    Update
                  </Button>
                  <Button
                    variant='outline-secondary'
                    onClick={() => {
                      toggleEditUserModal(false);
                    }}
                    className='ml-2'
                  >
                    Cancel
                  </Button>
                </Form>
              )}
            </Formik>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return { user: state.user };
};

export default connect(mapStateToProps)(ShipperUsers);
