import React from 'react'

const Spinner = ({ display, children }) => {
    return (
        <>
            <div className={display ? 'm-5 text-center' : 'd-none'}>
                <div className='row'>
                    <div className='col-lg-4 offset-lg-4'>
                        <div className=' font-weight-bold spinner-border text-primary' role='status'>
                            <span className='sr-only'>Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className={display ? 'd-none' : ''}>
                {children}
            </div>
        </>
    )
}

export default Spinner
