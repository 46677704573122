/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { trackPageView } from '../helpers/Analytics';

const Header = ({ title, name, children }) => {

    useEffect(() => {
        document.title = name !== undefined ? name : title;
        trackPageView();
    }, [])

    return (
        <nav className='header'>
            <div className='container-fluid'>
                <div className='header-body'>
                    <Row className='align-items-center'>
                        <Col>
                            <h6 className='header-pretitle'>{title}</h6>
                            <h1 className='header-title'>{name}</h1>
                        </Col>
                        {
                            children &&
                            <div className='col-auto text-right'>
                                {children}
                            </div>
                        }
                    </Row>
                </div>
            </div>
        </nav>
    );
}

export default Header;