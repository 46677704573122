import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL;

const auth = async (credentials) => {
  try {
    const URL = BASE_URL.concat('auth');
    const data = await axios.post(URL, credentials);
    return data;
  } catch (err) {
    return err;
  }
};

const auth_msal = async (account) => {
  try {
    const URL = BASE_URL.concat('auth/msal');
    const data = await axios.post(URL, account);
    return data;
  } catch (err) {
    return err;
  }
};

const forgotPassword = async (email) => {
  try {
    const URL = BASE_URL.concat('auth/send-password-reset-email');
    const data = await axios.post(URL, email);
    return data;
  } catch (err) {
    return err;
  }
};

const resetPassword = async (password, token) => {
  axios.defaults.headers.common['Authorization'] = token;
  try {
    const URL = BASE_URL.concat('me/update-password');
    const data = await axios.post(URL, password);
    return data;
  } catch (err) {
    return err;
  }
};

export { auth, auth_msal, forgotPassword, resetPassword };
