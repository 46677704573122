import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import swal from 'sweetalert';
import { Formik } from 'formik';
import { sortBy } from 'lodash';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Spinner from '../../components/Spinner';
import Container from 'react-bootstrap/Container';
import { updateToken, toLocalDate } from '../../Utils';
import { getDefaultStoargeType, getStoargeTypeById, deleteShipperStorageSettingById, addShippersStorageTypeSetting, updateShippersStorageSettingById } from '../../services/index';

const StorageTypeSetting = (props) => {
  const settingSchema = Yup.object().shape({
    name: Yup.string().required('Name is required.'),
    sort_order: Yup.number().required('Sort Order is required.'),
  });

  const storageTypeSetting = {
    name: '',
    sort_order: 0,
  };

  const shipperId = props.shipperId;
  const [loading, showSpinner] = useState(false);
  const [storageType, setStorageType] = useState([]);
  const [defaultType, setDefaultType] = useState([]);
  const [storageSetting, setStorageSetting] = useState({});
  const [modalStorageType, showStorageTypeModal] = useState(false);
  const [showAddSettingModal, setShowAddSettingModal] = useState(false);
  const [showChangeSettingModal, setShowChangeSettingModal] = useState(false);

  useEffect(() => {
    getStorageType();
    getDefaultStoargeTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getStorageType = async () => {
    try {
      const response = await getStoargeTypeById(shipperId);
      if (response) {
        if (response.status === 200 || response.status === 201) {
          const storageTypes = sortBy(response?.data, 'sort_order');
          setStorageType(storageTypes);
        } else {
          if (response?.response?.status === 401 || response?.response?.data === 'Unauthorized') {
            await updateToken();
          }
          if (response?.response?.status >= 500) {
            toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDefaultStoargeTypes = async () => {
    try {
      const response = await getDefaultStoargeType();
      if (response) {
        if (response.status === 200 || response.status === 201) {
          if (response?.data) {
            const defaultStorageTypes = sortBy(response?.data, 'sort_order');
            setDefaultType(defaultStorageTypes);
          }
        } else {
          if (response?.response?.status === 401 || response?.response?.data === 'Unauthorized') {
            await updateToken();
          }
          if (response?.response?.status >= 500) {
            toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addSettingModal = () => {
    setShowAddSettingModal(true);
  };

  const hideAddSettingModal = () => {
    setShowAddSettingModal(false);
  };

  const changeSettingModal = (storageSetting) => {
    setStorageSetting(storageSetting);
    setShowChangeSettingModal(true);
  };

  const hideChangeSettingModal = () => {
    setShowChangeSettingModal(false);
  };

  const handleRemove = async (setting) => {
    swal({
      title: `Remove ${setting.name}`,
      text: `Are you sure that you want to remove ${setting.name} Storage Type?`,
      buttons: ['Cancel', 'Yes'],
      icon: 'error',
      dangerMode: true,
    }).then(async (status) => {
      if (status) {
        showSpinner(true);
        const response = await deleteShipperStorageSettingById(shipperId, setting.id);
        if (response) {
          if (response.status === 200 || response.status === 201) {
            getStorageType();
            showSpinner(false);
            toast.success(`${setting.name} Storage Type has been removed.`);
          } else {
            if (response.response.status === 401 || response.response.data === 'Unauthorized') {
              await updateToken();
            }
            if (response.response.status >= 500) {
              toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
            }
            showSpinner(false);
          }
        }
      }
    });
  };

  const updateStorage = async (data) => {
    const custom_setting = {
      name: data.name,
      sort_order: data.sort_order,
    };
    const response = await updateShippersStorageSettingById(shipperId, data.id, custom_setting);
    if (response) {
      if (response.status === 200 || response.status === 201) {
        hideAddSettingModal();
        hideChangeSettingModal();
        getStorageType();
        const message = `${data.name} Storage Type has been updated.`;
        toast.success(message);
        showSpinner(false);
      } else {
        if (response?.response?.status === 409) {
          toast.error(response.response.data.error);
        }
        if (response.response.status === 401 || response.response.data === 'Unauthorized') {
          await updateToken();
        }
        if (response.response.status >= 500) {
          toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
        }
        showSpinner(false);
      }
    }
  };

  const addStorage = async (data) => {
    const custom_setting = {
      name: data.name,
      sort_order: data.sort_order,
    };
    const duplicateStorageType = storageType.find((item) => item.name === data.name);
    if (duplicateStorageType) {
      showSpinner(false);
      toast.error(`${data.name} Storage Type already exist.`);
    } else {
      const response = await addShippersStorageTypeSetting(shipperId, custom_setting);
      if (response) {
        if (response.status === 200 || response.status === 201) {
          hideAddSettingModal();
          hideChangeSettingModal();
          getStorageType();
          const message = `${data.name} Storage Type has been added.`;
          toast.success(message);
          showSpinner(false);
        } else {
          if (response?.response?.status === 409) {
            toast.error(response.response.data.error);
          }
          if (response.response.status === 401 || response.response.data === 'Unauthorized') {
            await updateToken();
          }
          if (response.response.status >= 500) {
            toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
          }
          showSpinner(false);
        }
      }
    }
  };

  const handleSubmit = async (data, isEdit) => {
    showSpinner(true);
    if (isEdit) {
      updateStorage(data);
    } else {
      addStorage(data);
    }
  };

  return (
    <>
      <Container>
        <Card>
          <Card.Header>
            <Row>
              <Col></Col>
              <Col sm='auto' className='p-0 mr-3 text-right'>
                <Button variant='white' onClick={() => showStorageTypeModal(true)}>
                  Default Setting
                </Button>
              </Col>
              <Col sm='auto' className='p-0 text-right'>
                <Button variant='white' onClick={addSettingModal}>
                  Add Custom Setting
                </Button>
              </Col>
            </Row>
          </Card.Header>
          <Spinner display={loading}>
            <Table responsive size='sm'>
              <thead>
                <tr>
                  <th className='text-center'>Sort Order</th>
                  <th>Name</th>
                  <th>Created Date</th>
                  <th>Modified Date</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {storageType &&
                  storageType.map((setting, index) => {
                    return (
                      <tr key={index}>
                        <td className='text-center'>{setting?.sort_order}</td>
                        <td>{setting.name}</td>
                        <td>
                          {toLocalDate(setting.create_date, 'LT')}
                          <div className='small text-muted'>{toLocalDate(setting.create_date, 'll')}</div>
                        </td>
                        <td>
                          {toLocalDate(setting.modify_date, 'LT')}
                          <div className='small text-muted'>{toLocalDate(setting.modify_date, 'll')}</div>
                        </td>
                        <td className='text-right'>
                          <div className='dropdown'>
                            <a href='/#' className='dropdown-ellipses dropdown-toggle' role='button' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                              <i className='fe fe-more-vertical'></i>
                            </a>
                            <div className='dropdown-menu dropdown-menu-right'>
                              <button
                                className='dropdown-item'
                                onClick={() => {
                                  changeSettingModal(setting);
                                }}
                              >
                                Change
                              </button>
                              <button
                                className='dropdown-item'
                                onClick={() => {
                                  handleRemove(setting);
                                }}
                              >
                                Remove
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </Spinner>
        </Card>
      </Container>

      <Modal show={showAddSettingModal} onHide={hideAddSettingModal}>
        <Modal.Body>
          <Modal.Title className='h1'>Add Setting</Modal.Title>
          <hr />
          <Formik initialValues={storageTypeSetting} validationSchema={settingSchema} onSubmit={(data) => handleSubmit(data, false)}>
            {({ handleChange, handleSubmit, values, errors }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>
                        Name<span className='text-danger'>*</span>
                      </Form.Label>
                      <Form.Control type='text' name='name' value={values.name} onChange={handleChange} isInvalid={!!errors.name} />
                      <Form.Control.Feedback type='invalid'>{errors.name}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>
                        Sort Order<span className='text-danger'>*</span>
                      </Form.Label>
                      <Form.Control type='number' min='0' name='sort_order' value={values.sort_order} onChange={handleChange} isInvalid={!!errors.sort_order} />
                      <Form.Control.Feedback type='invalid'>{errors.sort_order}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <br />
                <Button className='mt-3' type='submit' variant='primary'>
                  Add Setting
                </Button>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>

      <Modal show={showChangeSettingModal} onHide={hideChangeSettingModal}>
        <Modal.Body>
          <Modal.Title className='h1 mb-0'>{storageSetting.name}</Modal.Title>
          <hr />
          <Formik initialValues={storageSetting} validationSchema={settingSchema} onSubmit={(data) => handleSubmit(data, true)}>
            {({ handleChange, handleSubmit, values, errors }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>
                        Name<span className='text-danger'>*</span>
                      </Form.Label>
                      <input type='name' className='form-control' name='name' value={values.name} onChange={handleChange} isInvalid={!!errors.name} />
                      <Form.Control.Feedback type='invalid'>{errors.name}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>
                        Sort Order<span className='text-danger'>*</span>
                      </Form.Label>
                      <Form.Control type='number' min='0' name='sort_order' value={values.sort_order} onChange={handleChange} isInvalid={!!errors.sort_order} />
                      <Form.Control.Feedback type='invalid'>{errors.sort_order}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <br />
                <Button className='mt-3' type='submit' variant='primary'>
                  Add Setting
                </Button>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
      <Modal show={modalStorageType} onHide={() => showStorageTypeModal(false)}>
        <Modal.Body>
          <Modal.Title className='h1'>Default Storage Type</Modal.Title>
          <hr />
          <Row>
            {defaultType.map((data) => {
              return (
                <Col xs={6}>
                  <Form.Group>
                    <Form.Control type='text' name='text' value={data.name} readOnly />
                  </Form.Group>
                </Col>
              );
            })}
          </Row>
          <hr />
          <Button
            variant='secondary'
            onClick={() => {
              showStorageTypeModal(false);
            }}
            className='ml-2'
          >
            Close
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return { user: state.user };
};
export default connect(mapStateToProps)(StorageTypeSetting);
