import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { toast } from 'react-toastify';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Spinner from '../../components/Spinner';
import Container from 'react-bootstrap/Container';
import { resetPassword } from '../../services/auth';
import InputGroup from 'react-bootstrap/InputGroup';
import { trackPageView } from '../../helpers/Analytics';

const ResetPassword = (props) => {

  const token = `Bearer ${props.match.params.token}`;

  const initialValues = {
    password: '',
    confirmPassword: '',
  };

  
  const forgotPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .required('Password is required')
      .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&-])[A-Za-z\d@$!%*#?&-]{8,}$/, 'Must contain 8 characters, one uppercase, one lowercase, one number and one special character'),
    confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
  });


  const [loading, showSpinner] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    document.title = `Reset Password`;
    trackPageView();
  }, []);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmShowPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };


  const updatePassword = async (event) => {
    const { password } = event;
    showSpinner(true);
    try {
      const request = { password: password };
      const response = await resetPassword(request, token);
      if (response) {
        if (response.status === 200 || response.status === 201) {
          toast.info(`Your password has been updated, please login to continue`);
          showSpinner(false);
          props.history.push('/');
        } else {
          toast.warn(`An error occured while trying to process your request. Please try again or contact support@phoxhealth.com`);
          showSpinner(false);
        }
      }
    } catch (error) {
      console.log(error);
      toast.warn(`An error occured while trying to process your request. Please try again or contact support@phoxhealth.com`);
      showSpinner(false);
    }
  };

  return (
    <>
      <div className='d-flex align-items-center bg-auth border-top border-top-2 border-primary vh-100' style={{ display: 'block' }}>
        <Container>
          <Row className='justify-content-center'>
            <Col className='col-12 col-md-5 col-xl-4 my-5'>
              <div className='text-center'>
                <Image src='/img/logo.png' alt='...' className='img-fluid mb-5' />
                <h1 className='display-4 mb-3'>Reset Password</h1>
                <p className='text-muted  mb-5'>Enter a secure password to reset your account</p>
              </div>
              <Spinner display={loading}>
                <Formik initialValues={initialValues} validationSchema={forgotPasswordSchema} onSubmit={updatePassword}>
                  {({ handleChange, handleSubmit, values, errors }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                      <Form.Group>
                        <InputGroup className='input-group-merge'>
                          <Form.Control type={showPassword ? 'text' : 'password'} name='password' placeholder='Enter Password' className='form-control-appended' value={values.password} onChange={handleChange} isInvalid={!!errors.password} />
                          <InputGroup.Append>
                            <InputGroup.Text id='showPassword' onClick={toggleShowPassword}>
                              <i className={showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'}></i>
                            </InputGroup.Text>
                          </InputGroup.Append>
                          <Form.Control.Feedback type='invalid'>{errors.password}</Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                      <Form.Group>
                        <div className='input-group input-group-merge'>
                          <Form.Control type={showConfirmPassword ? 'text' : 'password'} name='confirmPassword' placeholder='Confirm password' className='form-control-appended' value={values.confirmPassword} onChange={handleChange} isInvalid={!!errors.confirmPassword} />
                          <div className='input-group-append'>
                            <span className='input-group-text' onClick={toggleConfirmShowPassword}>
                              <i className={showConfirmPassword ? 'fa fa-eye' : 'fa fa-eye-slash'} />
                            </span>
                          </div>
                          <Form.Control.Feedback type='invalid'>{errors.confirmPassword}</Form.Control.Feedback>
                        </div>
                      </Form.Group>
                      <Button type='submit' className='btn btn-lg btn-block btn-primary mb-3 btn-login'>
                        Reset Password
                      </Button>
                      <div className='text-center text-muted small'>
                        Remember your password? <Link to='/'>Sign In</Link>
                      </div>
                    </Form>
                  )}
                </Formik>
              </Spinner>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ResetPassword;
