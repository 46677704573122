/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

const Navigation = (props) => {

  const [dashboardExpand, setDashboardExpand] = useState(true);
  const doLogout = async () => {
    localStorage.clear();
  };
  const { user } = props;
  const profileImage = user && user.photoUrl;
  return (
    <>
      <nav className='navbar navbar-vertical fixed-left navbar-expand-md navbar-light' id='sidebar'>
        <div className='container-fluid'>
          <button className='navbar-toggler' type='button' data-toggle='collapse' data-target='#sidebarCollapse' aria-controls='sidebarCollapse' aria-expanded='false' aria-label='Toggle navigation'>
            <span className='navbar-toggler-icon'></span>
          </button>
          <Link className='navbar-brand'>
            <img src='/img/logo.png' alt='...' className='navbar-brand-img' />
          </Link>
          <div className='collapse navbar-collapse' id='sidebarCollapse'>
            <ul className='navbar-nav'>
              <div className="d-lg-flex flex-column justify-content-between nav-height">
                <div>
                  <li className='nav-item '>
                    <div className={`nav-link mr-4 ${window.location.pathname.indexOf('/dashboard') === 0 ? 'active' : window.location.pathname.indexOf('/dashboard') === 0 ? 'active' : ''}`} onClick={() => setDashboardExpand(!dashboardExpand)} data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarDashboards">
                      <i className="fe fe-home"></i> Dashboards <i className={`ml-6 fe fe-${dashboardExpand?"chevron-down":"chevron-up"}`}></i>
                    </div>
                    <div className={`collapse ${dashboardExpand ? "show" : ''}`} id="sidebarDashboards" >
                      <ul className="nav nav-sm flex-column"><li className="nav-item" >
                          <a href='/dashboard/today' className="nav-link " >
                            Today
                          </a>
                        </li>
                        <li className="nav-item" >
                          <a href='/dashboard/delivery-performance' className="nav-link " >
                            Delivery Performance
                          </a>
                        </li>
                        <li className="nav-item" >
                          <a href='/dashboard/live-tracking' target='_blank' className="nav-link " >
                            Live Tracking
                          </a>
                        </li>
                        <li className="nav-item" >
                          <a href='/dashboard/driver' target='_blank' className="nav-link " >
                            Driver Tracker
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href='/dashboard/nps' className="nav-link " >
                            NPS Dashboard
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li className='nav-item'>
                    <Link to='/shipment' className={`nav-link mr-4 mt-3 ${window.location.pathname.indexOf('/shipments') === 0 ? 'active' : window.location.pathname.indexOf('/shipment') === 0 ? 'active' : ''}`}>
                      <i className="fe fe-package"></i>Shipments
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link to='/shipper' className={`nav-link mr-4 mt-3 ${window.location.pathname.indexOf('/shipper') === 0 ? 'active' : ''}`}>
                      <i className="fe fe-box"></i>Shippers
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link to='/carrier' className={`nav-link mr-4 mt-3 ${window.location.pathname.indexOf('/carrier') === 0 ? 'active' : ''}`}>
                      <i className="fe fe-truck"></i>Couriers
                    </Link>
                  </li>
                </div>
                <div>
                  <hr className='dropdown-divider' />
                  <li className='nav-item '>
                    {/* <div className='d-flex justify-content-center align-items-center' data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarDashboards" onClick={() => setProfileExpand(!profileExpand)}>
                      <img src={profileImage ? profileImage : '/img/default-user.png'} style={{ width: '45px ' }} alt='...' className='avatar-img rounded-circle' />
                    </div>
                    <div className={`mt-3 collapse ${profileExpand ? "show" : ''}`}  >
                      <ul className="nav nav-sm flex-column">
                        <li className="nav-item" >
                          <a href='/profile' className="nav-link " >
                            <i className="fe fe-user"></i>My Profile
                          </a>
                        </li>
                        <li className="nav-item" >
                          <a href='/' className="nav-link " >
                            <i className="fe fe-log-out"></i>Logout
                          </a>
                        </li>
                      </ul>
                    </div> */}
                      <div className='dropup'>
                        <div className='d-flex justify-content-center align-items-center'>
                        <a  href='#!' className='avatar avatar-sm dropdown-toggle' role='button' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                          <img src={profileImage ? profileImage : '/img/default-user.png'} alt='...' className='avatar-img rounded-circle' />
                        </a></div>
                        <div className='dropdown-menu align-center'>
                          <Link to='/profile' className='dropdown-item'>
                            My Profile
                          </Link>
                          <Link to='/' className='dropdown-item' onClick={() => doLogout()}>
                            Logout
                          </Link>
                        </div>
                      </div>
                  </li>
                </div>
              </div>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

const mapStateToProps = (state) => {
  return { user: state.user };
};

export default connect(mapStateToProps)(Navigation);
