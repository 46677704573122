/* eslint-disable no-sequences */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import { toast } from 'react-toastify';
import momentTz from 'moment-timezone';
import Dropdown from 'react-bootstrap/Dropdown';
import DateRangePicker from 'react-bootstrap-daterangepicker';

import Navbar from '../../components/Navigation';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import NumberFormat from 'react-number-format';
import InfoCard from '../../components/InfoCard';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { Bar } from 'react-chartjs-2';
import { getDashboardData, getAllGroups } from '../../services/index';

import { updateToken, toUtcDate, toLocalDate } from '../../Utils';

require('./RoundedBars');

const PerformanceDashboard = (props) => {
  const timeZone = `${momentTz.tz.guess()}`;
  const [dateFilters, setDateFilters] = useState({
    fromDate: `${moment().subtract(13, 'days').format('YYYY-MM-DD')}T00:00:00`,
    toDate: `${moment().format('YYYY-MM-DD')}T23:59:59`,
  });

  const [groupFilter, setGroupFilter] = useState('');

  const [averageNPS, setAverageNPS] = useState(0);
  const [shipmentCount, setShipmentCount] = useState(0);
  const [shipmentByDay, setShipmentByDay] = useState({});
  const [averageFeedback, setAverageFeedback] = useState(0);
  const [shipmentByGroup, setShipmentByGroup] = useState([]);
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    // getShipmentCount();
    // getAverageNPS();
    // getAverageFeedback();
    // getShipmentByGroup();
    // getShipmentByDay();
    getGroups();
  }, [dateFilters.fromDate, dateFilters.toDate]);

  const getShipmentCount = async () => {
    const url = `dashboard/shipment-count?from_date=${toUtcDate(dateFilters.fromDate)}&to_date=${toUtcDate(dateFilters.toDate)}`;
    const response = await getDashboardData(url);
    if (response) {
      if (response.status === 200 || response.status === 201) {
        setShipmentCount(response?.data.count);
      } else {
        if (response.response.status === 401 || response.response.data === 'Unauthorized') {
          await updateToken();
        }
        if (response.response.status >= 500) {
          toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
        }
      }
    }
  };

  const getAverageNPS = async () => {
    const url = `dashboard/feedback-nps-average?from_date=${toUtcDate(dateFilters.fromDate)}&to_date=${toUtcDate(dateFilters.toDate)}`;
    const response = await getDashboardData(url);
    if (response) {
      if (response.status === 200 || response.status === 201) {
        setAverageNPS(Math.round(Number(response?.data.nps) * 100));
      } else {
        if (response.response.status >= 500) {
          toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
        }
      }
    }
  };

  const getAverageFeedback = async () => {
    const url = `dashboard/feedback-average?from_date=${toUtcDate(dateFilters.fromDate)}&to_date=${toUtcDate(dateFilters.toDate)}`;
    const response = await getDashboardData(url);
    if (response) {
      if (response.status === 200 || response.status === 201) {
        setAverageFeedback(response?.data.avg);
      } else {
        if (response.response.status >= 500) {
          toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
        }
      }
    }
  };

  const getShipmentByDay = async () => {
    const url = `dashboard/shipment-by-day?from_date=${toUtcDate(dateFilters.fromDate)}&to_date=${toUtcDate(dateFilters.toDate)}&status=delivered&time_zone=${timeZone}`;
    const response = await getDashboardData(url);
    if (response) {
      if (response.status === 200 || response.status === 201) {
        let labels = [];
        const shipmentByDayData = Object.entries(response?.data)
          .sort()
          .reduce((o, [k, v]) => ((o[k] = v), o), {});
        for (let date of Object.keys(shipmentByDayData)) {
          labels.push(toLocalDate(date, 'll'));
        }
        const data = {
          labels: labels,
          datasets: [
            {
              label: 'Deliveries',
              data: Object.values(shipmentByDayData),
              backgroundColor: '#2c7be5',
              borderWidth: 0.1,
            },
          ],
        };
        setShipmentByDay(data);
      } else {
        if (response.response.status >= 500) {
          toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
        }
      }
    }
  };

  const getShipmentByGroup = async () => {
    const url = `dashboard/shipment-by-shipper-group?from_date=${toUtcDate(dateFilters.fromDate)}&to_date=${toUtcDate(dateFilters.toDate)}&status=delivered`;
    const response = await getDashboardData(url);
    if (response) {
      if (response.status === 200 || response.status === 201) {
        setShipmentByGroup(response.data);
      } else {
        if (response.response.status >= 500) {
          toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
        }
      }
    }
  };

  const getGroups = async () => {
    const response = await getAllGroups();
    if (response) {
      if (response.status === 200 || response.status === 201) {
        const data = response.data;
        let group = data.map((group, index) => ({
          id: index + 1,
          name: group,
        }));
        group = [...new Set(group)];
        setGroups(group);
      } else {
        if (response.response.status >= 500) {
          toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
        }
      }
    }
  };

  const handleDateChange = (event, picker) => {
    const fromDate = `${moment(picker.startDate).format('YYYY-MM-DD')}T00:00:00`;
    const toDate = `${moment(picker.endDate).format('YYYY-MM-DD')}T23:59:59`;
    setDateFilters({ fromDate, toDate });
  };

  const handleGroupChange = (event, dropdown) => {
    setGroupFilter(dropdown.name);
  }

  return (
    <>
      <Navbar />
      <div className="main-content">
        <Header title="Phox Health" name="Delivery Performance">
          <Row className="m-auto">
            <div className='col'>
              <Dropdown>
                <Dropdown.Toggle variant="light">Group: {!groupFilter ? <span>All</span> : groupFilter && <span>{groupFilter}</span>}</Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => { handleGroupChange('group', ''); }}>All</Dropdown.Item>
                  {
                    groups && groups.map((group, i) => {
                      return (
                        <Dropdown.Item key={i} onClick={() => { handleGroupChange('group', group); }}>{group.name}</Dropdown.Item>
                      );
                    })
                  }
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className='col'>
              <DateRangePicker
                initialSettings={{
                  startDate: moment().subtract(13, 'days'),
                  endDate: moment(),
                  linkedCalendars: true,
                  showCustomRangeLabel: true,
                  showDropdowns: true,
                  alwaysShowCalendars: true,
                  opens: 'left',
                  ranges: {
                    Today: [moment(), moment()],
                    Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                    'Last 14 Days': [moment().subtract(13, 'days'), moment()],
                    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                    'This Month': [moment().startOf('month'), moment().endOf('month')],
                    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                  },
                }}
                onEvent={handleDateChange}
              >
                <input className="btn btn-light" />
              </DateRangePicker>
            </div>
          </Row>
        </Header>
        <div className='container-fluid'>
          <Row>
            <div className='col'>
              <InfoCard title="Deliveries" icon={'/img/dashboard/Shipments.svg'}>
                {shipmentCount}
              </InfoCard>
            </div>
            <div className='col'>
              <InfoCard title="Success Rate" icon={'/img/dashboard/Shipments.svg'}>
                94%
              </InfoCard>
            </div>
            <div className='col'>
              <InfoCard title="Customer Feedback" icon={'/img/dashboard/Average Feedback.svg'}>
                <NumberFormat value={averageFeedback ? averageFeedback : 0} displayType={'text'} decimalScale={1}></NumberFormat>
              </InfoCard>
            </div>
            <div className='col'>
              <InfoCard title="Net Promoter Score" icon={'/img/dashboard/Nps Score.svg'}>
                <NumberFormat value={averageNPS} displayType={'text'} decimalScale={1}></NumberFormat>
              </InfoCard>
            </div>
          </Row>
          <div className='row'>
            <div className='col'>
              <InfoCard title='On Time' icon={'/img/dashboard/Nps Score.svg'}>

              </InfoCard>
            </div>
            <div className='col'>
              <InfoCard title='Late' icon={'/img/dashboard/Nps Score.svg'}>

              </InfoCard>
            </div>
            <div className='col'>
              <InfoCard title='Super Late' icon={'/img/dashboard/Nps Score.svg'}>

              </InfoCard>
            </div>
          </div>
          <div className='row'>
            <div className='col-8'>
              <div className='card'>
                <div className='card-header'>
                  <h4 className='card-header-title'>Deliveries by Day</h4>
                </div>
                <div className='card-body'>
                  <Bar width={150} height={400} data={shipmentByDay}
                    options={{
                      scales: {
                        xAxes: [
                          {
                            barThickness: 22, // number (pixels) or 'flex'
                            maxBarThickness: 24, // number (pixels)
                          },
                        ],
                      },
                      maintainAspectRatio: false,
                      cornerRadius: 18,
                    }}
                  />
                </div>
              </div>
            </div>
            <div className='col-4'>
              <div className='card'>
                <div className='card-header'>
                  <h4 className='card-header-title'>Deliveries by Group</h4>
                </div>
                <div className='card-body'>
                  <div className='list-group list-group-flush my-n3'>
                    {
                      shipmentByGroup.map((row, i) => {
                        return (
                          <div className='list-group-item' key={i}>
                            <div className='row align-items-center'>
                              <div className='col'>{row.group_name}</div>
                              <div className='col-auto text-muted'>{row.shipment_count}</div>
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default PerformanceDashboard;
