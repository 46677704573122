/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import moment from 'moment';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import { updateToken } from '../../Utils';
import MaskedInput from 'react-text-mask';
import Button from 'react-bootstrap/Button';
import Navbar from '../../components/Navigation';
import Header from '../../components/Header';
import Input from '@material-ui/core/Input';
import Spinner from '../../components/Spinner';
import Container from 'react-bootstrap/Container';
import { GoogleApiWrapper } from 'google-maps-react';
import { getShippersById, addEditShipper, getShipperTimeZone } from '../../services/index';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

const TextMaskCustom = (props) => {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
      guide={false}
    />
  );
};

const shipperSchema = Yup.object().shape({
  name: Yup.string().required('Name is required.'),
  alias: Yup.string().required('Alias is required.'),
  group_name: Yup.string().required('Group is required.'),
  email: Yup.string().email('Email is invalid.').required('Email is required.'),
  //phone: Yup.string().matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, 'Please enter a valid phone number.'),
  fax: Yup.string().matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, 'Please enter a valid phone number.'),
  address1: Yup.string().required('Address1 is required.'),
  city: Yup.string().required('City is required.'),
  state: Yup.string().required('State is required.'),
  postal_code: Yup.string()
    .matches(/^[0-9]{5}(?:-[0-9]{4})?$/, 'Please enter a valid postal code.')
    .required('Postal Code is required.'),
});

const shipperInitialValues = {
  name: '',
  alias: '',
  group_name: '',
  phone: '',
  fax: '',
  email: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  postal_code: '',
  location: {},
  timezone: {}
};

const AddEditShipper = (props) => {
  const shipperId = props.match.params.id;
  const [loading, showSpinner] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState({ data: '', is_valid: true });
  const [shipperDetails, setShipperDetails] = useState(shipperInitialValues);

  useEffect(() => {
    if (shipperId) {
      getShipper();
    }
  }, []);

  const getShipper = async () => {
    await showSpinner(true);
    const response = await getShippersById(shipperId);
    if (response) {
      if (response.status === 200 || response.status === 201) {
        let shipper = response?.data;
        shipper.address1 = shipper.address.address1;
        shipper.address2 = shipper.address.address2;
        shipper.location = shipper.address.location;
        shipper.timezone = shipper.address.timezone;
        shipper.city = shipper.address.city;
        shipper.state = shipper.address.state;
        shipper.postal_code = shipper.address.postal_code;
        setShipperDetails(shipper);
        const newValue = shipper?.phone && shipper?.phone.replace('(', '').replace(')', '').replace('-', '').replace(' ', '');
        setPhoneNumber({
          data: shipper?.phone,
          is_valid: newValue.length > 0 && newValue.length < 10 ? false : true,
        });
        await showSpinner(false);
      } else {
        if (response.response.status === 401 || response.response.data === 'Unauthorized') {
          await updateToken();
        }
        if (response.response.status >= 500) {
          toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
        }
      }
    }
  };

  const setShipperTimeZone = async (LatLong) => {
    const currentTime = moment().utc().format('HH:mm:ss').split(':');
    const timeInMilliseconds = currentTime[0] * 3600000 + currentTime[1] * 60000;
    const request = {
      location: {
        latitude: LatLong.lat,
        longitude: LatLong.lng,
      },
      timestamp: timeInMilliseconds,
    };
    const response = await getShipperTimeZone(request);
    if (response) {
      if (response.status === 200 || response.status === 201) {
        const shipperTimeZone = response?.data;
        const timezone = {
          timezone_id: shipperTimeZone.timeZoneId,
          timezone_name: shipperTimeZone.timeZoneName,
          timezone_alias: shipperTimeZone.timeZoneName.match(/\b(\w)/g)?.join(''),
        }
        return timezone;
      } else {
        if (response.response.status === 401 || response.response.data === 'Unauthorized') {
          await updateToken();
        }
        if (response.response.status >= 500) {
          toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
          return {}
        }
      }
    }
  };

  const handleSubmit = async (shipper) => {
    const request = {
      address: { address1: shipper.address1.trim(), address2: shipper.address2.trim() || '', location: shipper.location, city: shipper.city.trim(), state: shipper.state.trim(), postal_code: shipper.postal_code, timezone: shipper.timezone },
      alias: shipper.alias,
      email: shipper.email.toLowerCase().trim(),
      fax: shipper.fax,
      group_name: shipper.group_name.trim(),
        // .toLowerCase()
        // .replace(/\w/, (firstLetter) => firstLetter.toUpperCase())
      name: shipper.name,
      phone: phoneNumber.data,
    };
    await showSpinner(true);
    const url = shipperId ? `shipper/${shipperId}` : 'shipper';
    const response = await addEditShipper(url, request);
    if (response) {
      if (response.status === 200 || response.status === 201) {
        const message = shipperId ? 'Shipper has been updated' : 'Shipper has been added';
        props.history.push('/shipper');
        toast.success(message);
        props.history.push('/shipper');
      } else {
        if (response.response.status === 401 || response.response.data === 'Unauthorized') {
          await updateToken();
        }
        if (response.response.status >= 500) {
          toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
        }
      }
    }
  };

  const handleAddressSelect = async (value) => {
    const selectedAddress = await geocodeByAddress(value);
    const LatLong = await getLatLng(selectedAddress[0]);
    console.log(selectedAddress, LatLong)
    let street_number = '';
    let sub_locality1 = '';
    let sub_locality2 = '';
    let sub_locality3 = '';
    let route = '';
    let city = '';
    let state = '';
    let postal_code = '';
    let location = {};
    let timezone = {};
    if (LatLong) {
      location._latitude = LatLong.lat;
      location._longitude = LatLong.lng;
      timezone = await setShipperTimeZone(LatLong);
    }
    !!selectedAddress[0].address_components.length &&
      selectedAddress[0].address_components.forEach((address) => {
        if (address.types.includes('street_number')) street_number = address.short_name;
        if (address.types.includes('sublocality_level_3')) sub_locality1 = address.short_name;
        if (address.types.includes('sublocality_level_2')) sub_locality2 = address.short_name;
        if (address.types.includes('sublocality_level_1')) sub_locality3 = address.short_name;
        if (address.types.includes('route')) route = address.short_name;
        if (address.types.includes('locality')) city = address.short_name;
        if (address.types.includes('administrative_area_level_1')) state = address.short_name;
        if (address.types.includes('postal_code')) postal_code = address.long_name;
      });

    setShipperDetails({
      ...shipperDetails,
      address1: street_number ? `${street_number} ${route}` : `${sub_locality1} ${sub_locality2} ${sub_locality3} ${route}`,
      postal_code,
      city,
      state,
      location,
      timezone
    });
  };

  const handleAddress1Change = (address1, values) => {
    setShipperDetails({
      ...values,
      address1,
    });
  };

  const handlePhoneChange = ({ target: { value } }) => {
    const newValue = value && value.replace('(', '').replace(')', '').replace('-', '').replace(' ', '');
    setPhoneNumber({
      data: value,
      is_valid: newValue.length > 0 && newValue.length < 10 ? false : true,
    });
  };

  return (
    <>
    <Navbar />
      <div className='main-content'>
        <Spinner display={loading}>
          <Header title="Shippers" name={shipperId ? shipperDetails.name : 'New Shipper'}></Header>
          <Container>
            <Card>
              <Card.Body>
                <Formik enableReinitialize={true} initialValues={shipperDetails} validationSchema={shipperSchema} onSubmit={handleSubmit}>
                  {({ handleChange, handleSubmit, values, errors }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                      <Form.Group>
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" name="name" placeholder="e.g. ABC Company" value={values.name} onChange={handleChange} isInvalid={!!errors.name} />
                        <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Alias</Form.Label>
                        <Form.Control type="text" name="alias" placeholder="e.g. ABC Company" value={values.alias} onChange={handleChange} isInvalid={!!errors.alias} />
                        <Form.Control.Feedback type="invalid">{errors.alias}</Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Group</Form.Label>
                        <Form.Control type="text" name="group_name" placeholder="e.g. ABC Group" value={values.group_name} onChange={handleChange} isInvalid={!!errors.group_name} />
                        <Form.Control.Feedback type="invalid">{errors.group_name}</Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" name="email" placeholder="e.g. john@phoxhealth.com" value={values.email} onChange={handleChange} isInvalid={!!errors.email} />
                        <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                      </Form.Group>
                      <Row>
                        <Col lg={6}>
                          <Form.Group>
                            <Form.Label>Phone</Form.Label>
                            <Input error={phoneNumber.is_valid ? false : true} type="tel" className="form-control" id="formatted-text-mask-input" value={phoneNumber.data} onChange={handlePhoneChange} name="textmask" placeholder="( _ _ _ ) _ _ _ - _ _ _ _" inputComponent={TextMaskCustom} />
                            {!phoneNumber.is_valid && <span className="text-danger">Please enter a valid phone number.</span>}
                          </Form.Group>
                        </Col>
                        <Col lg={6}>
                          <Form.Group>
                            <Form.Label>Fax</Form.Label>
                            <Form.Control type="phone" name="fax" placeholder="e.g. +1 301-123-4566" value={values.fax} onChange={handleChange} isInvalid={!!errors.fax} />
                            <Form.Control.Feedback type="invalid">{errors.fax}</Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>

                      <hr />

                      <Row>
                        <Col lg={6}>
                          <Form.Group>
                            <Form.Label>
                              Address Line 1<span className="text-danger">*</span>
                            </Form.Label>
                            <PlacesAutocomplete
                              name="address1"
                              value={values.address1}
                              onChange={(data) => {
                                handleAddress1Change(data, values);
                              }}
                              onSelect={handleAddressSelect}
                            >
                              {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                                <div className="search-input-container col-12 p-0 mb-4">
                                  <Form.Control {...getInputProps({ placeholder: 'e.g. 100 W Main St.', className: 'form-control business-form' })} isInvalid={!!errors.address1} />
                                  <Form.Control.Feedback type="invalid">{errors.address1}</Form.Control.Feedback>
                                  <div className="autocomplete-container bg-light">
                                    {suggestions.map((suggestion, index) => {
                                      const className = 'suggestion-item';
                                      return (
                                        <div key={index} {...getSuggestionItemProps(suggestion, { className })}>
                                          <span>{suggestion.description}</span>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              )}
                            </PlacesAutocomplete>
                          </Form.Group>
                        </Col>
                        <Col lg={6}>
                          <Form.Group>
                            <Form.Label>Address 2</Form.Label>
                            <Form.Control type="text" name="address2" placeholder="e.g. Suite 100" value={values.address2} onChange={handleChange} isInvalid={!!errors.address2} />
                            <Form.Control.Feedback type="invalid">{errors.address2}</Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg={4}>
                          <Form.Group>
                            <Form.Label>City</Form.Label>
                            <Form.Control type="text" name="city" placeholder="e.g. +1 301-123-4566" value={values.city} onChange={handleChange} isInvalid={!!errors.city} />
                            <Form.Control.Feedback type="invalid">{errors.city}</Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col lg={4}>
                          <Form.Group>
                            <Form.Label>State</Form.Label>
                            <Form.Control type="text" name="state" placeholder="e.g. +1 301-123-4566" value={values.state} onChange={handleChange} isInvalid={!!errors.state} />
                            <Form.Control.Feedback type="invalid">{errors.state}</Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col lg={4}>
                          <Form.Group>
                            <Form.Label>Postal Code</Form.Label>
                            <Form.Control type="text" name="postal_code" placeholder="e.g. 21044" value={values.postal_code} onChange={handleChange} isInvalid={!!errors.postal_code} />
                            <Form.Control.Feedback type="invalid">{errors.postal_code}</Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>

                      <hr />
                      <Button type="submit" variant="primary">
                        {props.match.params.id ? 'Update Shipper' : 'Create Shipper'}
                      </Button>
                      <Link to="/shipper" className="btn btn-white ml-2">
                        Cancel
                      </Link>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </Container>
        </Spinner>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return { user: state.user };
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  libraries: ['places', 'visualization'],
})(connect(mapStateToProps)(AddEditShipper));
