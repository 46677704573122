import React from 'react';
import Card from 'react-bootstrap/Card';

const Footer = () => {
  return (
    <>
      <Card className='mb-0 text-center small text-muted'>
        <Card.Body>Powered by Phox Health, Inc.</Card.Body>
      </Card>
    </>
  );
};

export default Footer;
