import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import swal from 'sweetalert';
import { Formik } from 'formik';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Spinner from '../../components/Spinner';
import Container from 'react-bootstrap/Container';
import { updateToken, toLocalDate } from '../../Utils';
import { getDefaultLabType, getLabTypeById, deleteLabSettingById, addLabTypeSetting, updateLabSettingById } from '../../services/index';

const LabTypeSetting = (props) => {

  const settingSchema = Yup.object().shape({
    name: Yup.string().required('Name is required.'),
  });

  const labTypeSetting = {
    name: '',
  };

  const shipperId = props.shipperId;
  const [labType, setLabType] = useState([]);
  const [loading, showSpinner] = useState(false);
  const [labSetting, setlabSetting] = useState({});
  const [defaultType, setDefaultType] = useState([]);
  const [modalLabType, showLabTypeModal] = useState(false);
  const [showAddSettingModal, setShowAddSettingModal] = useState(false);
  const [showChangeSettingModal, setShowChangeSettingModal] = useState(false);

  useEffect(() => {
    getLabType();
    getDefaultLabTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLabType = async () => {
    try {
      const response = await getLabTypeById(shipperId);
      if (response) {
        if (response.status === 200 || response.status === 201) {
          setLabType(response?.data);
        } else {
          if (response?.response?.status === 401 || response?.response?.data === 'Unauthorized') {
            await updateToken();
          }
          if (response?.response?.status >= 500) {
            toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDefaultLabTypes = async () => {
    try {
      const response = await getDefaultLabType();
      if (response) {
        if (response.status === 200 || response.status === 201) {
          if (response?.data) {
            setDefaultType(response?.data);
          }
        } else {
          if (response?.response?.status === 401 || response?.response?.data === 'Unauthorized') {
            await updateToken();
          }
          if (response?.response?.status >= 500) {
            toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addSettingModal = () => {
    setShowAddSettingModal(true);
  };

  const hideAddSettingModal = () => {
    setShowAddSettingModal(false);
  };

  const changeSettingModal = (labSetting) => {
    setlabSetting(labSetting);
    setShowChangeSettingModal(true);
  };

  const hideChangeSettingModal = () => {
    setShowChangeSettingModal(false);
  };

  const handleRemove = async (setting) => {
    swal({
      title: `Remove ${setting.name}`,
      text: `Are you sure that you want to remove ${setting.name} Product Type?`,
      buttons: ['Cancel', 'Yes'],
      icon: 'error',
      dangerMode: true,
    }).then(async (status) => {
      if (status) {
        showSpinner(true);
        const response = await deleteLabSettingById(shipperId, setting.id);
        if (response) {
          if (response.status === 200 || response.status === 201) {
            getLabType();
            showSpinner(false);
            toast.success(`${setting.name} Product Type has been removed.`);
          } else {
            if (response.response.status === 401 || response.response.data === 'Unauthorized') {
              await updateToken();
            }
            if (response.response.status >= 500) {
              toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
            }
            showSpinner(false);
          }
        }
      }
    });
  };

  const updateLabType = async (data) => {
    const custom_setting = {
      name: data.name,
    };
    const response = await updateLabSettingById(shipperId, data.id, custom_setting);
    if (response) {
      if (response.status === 200 || response.status === 201) {
        hideAddSettingModal();
        hideChangeSettingModal();
        getLabType();
        const message = `${data.name} Product Type has been updated.`;
        toast.success(message);
        showSpinner(false);
      } else {
        if (response?.response?.status === 409) {
          toast.error(response.response.data.error);
        }
        if (response.response.status === 401 || response.response.data === 'Unauthorized') {
          await updateToken();
        }
        if (response.response.status >= 500) {
          toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
        }
        showSpinner(false);
      }
    }
  };

  const addLabType = async (data) => {
    const custom_setting = {
      name: data.name,
    };
    const duplicateStorageType = labType.find((item) => item.name === data.name);
    if (duplicateStorageType) {
      showSpinner(false);
      toast.error(`${data.name} Product Type already exist.`);
    } else {
      const response = await addLabTypeSetting(shipperId, custom_setting);
      if (response) {
        if (response.status === 200 || response.status === 201) {
          hideAddSettingModal();
          hideChangeSettingModal();
          getLabType();
          const message = `${data.name} Product Type has been added.`;
          toast.success(message);
          showSpinner(false);
        } else {
          if (response?.response?.status === 409) {
            toast.error(response.response.data.error);
          }
          if (response.response.status === 401 || response.response.data === 'Unauthorized') {
            await updateToken();
          }
          if (response.response.status >= 500) {
            toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
          }
          showSpinner(false);
        }
      }
    }
  };

  const handleSubmit = async (data, isEdit) => {
    showSpinner(true);
    if (isEdit) {
      updateLabType(data);
    } else {
      addLabType(data);
    }
  };

  return (
    <>
      <Container>
        <Card>
          <Card.Header>
            <Row>
              <Col></Col>
              <Col sm='auto' className='p-0 mr-3 text-right'>
                <Button variant='white' onClick={() => showLabTypeModal(true)}>
                  Default Setting
                </Button>
              </Col>
              <Col sm='auto' className='p-0 text-right'>
                <Button variant='white' onClick={addSettingModal}>
                  Add Custom Setting
                </Button>
              </Col>
            </Row>
          </Card.Header>
          <Spinner display={loading}>
            <Table responsive size='sm'>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Created Date</th>
                  <th>Modified Date</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {labType &&
                  labType.map((setting, index) => {
                    return (
                      <tr key={index}>
                        <td>{setting.name}</td>
                        <td>
                          {toLocalDate(setting.create_date, 'LT')}
                          <div className='small text-muted'>{toLocalDate(setting.create_date, 'll')}</div>
                        </td>
                        <td>
                          {toLocalDate(setting.modify_date, 'LT')}
                          <div className='small text-muted'>{toLocalDate(setting.modify_date, 'll')}</div>
                        </td>
                        <td className='text-right'>
                          <div className='dropdown'>
                            <a href='/#' className='dropdown-ellipses dropdown-toggle' role='button' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                              <i className='fe fe-more-vertical'></i>
                            </a>
                            <div className='dropdown-menu dropdown-menu-right'>
                              <button
                                className='dropdown-item'
                                onClick={() => {
                                  changeSettingModal(setting);
                                }}
                              >
                                Change
                              </button>
                              <button
                                className='dropdown-item'
                                onClick={() => {
                                  handleRemove(setting);
                                }}
                              >
                                Remove
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </Spinner>
        </Card>
      </Container>

      <Modal show={showAddSettingModal} onHide={hideAddSettingModal}>
        <Modal.Body>
          <Modal.Title className='h1'>Add Setting</Modal.Title>
          <hr />
          <Formik initialValues={labTypeSetting} validationSchema={settingSchema} onSubmit={(data) => handleSubmit(data, false)}>
            {({ handleChange, handleSubmit, values, errors }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>
                        Name<span className='text-danger'>*</span>
                      </Form.Label>
                      <Form.Control type='text' name='name' value={values.name} onChange={handleChange} isInvalid={!!errors.name} />
                      <Form.Control.Feedback type='invalid'>{errors.name}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <br />
                <Button className='mt-3' type='submit' variant='primary'>
                  Add Setting
                </Button>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
      <Modal show={showChangeSettingModal} onHide={hideChangeSettingModal}>
        <Modal.Body>
          <Modal.Title className='h1 mb-0'>{labSetting.name}</Modal.Title>
          <hr />
          <Formik initialValues={labSetting} validationSchema={settingSchema} onSubmit={(data) => handleSubmit(data, true)}>
            {({ handleChange, handleSubmit, values, errors }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>
                        Name<span className='text-danger'>*</span>
                      </Form.Label>
                      <input type='name' className='form-control' name='name' value={values.name} onChange={handleChange} isInvalid={!!errors.name} />
                      <Form.Control.Feedback type='invalid'>{errors.name}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <br />
                <Button className='mt-3' type='submit' variant='primary'>
                  Add Setting
                </Button>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
      <Modal show={modalLabType} onHide={() => showLabTypeModal(false)}>
        <Modal.Body>
          <Modal.Title className='h1'>Default Product Type</Modal.Title>
          <hr />
          <Row>
            {defaultType.map((data) => {
              return (
                <>
                  <Col xs={6}>
                    <Form.Group>
                      <Form.Control type='text' name='text' value={data.name} readOnly />
                    </Form.Group>
                  </Col>
                </>
              );
            })}
          </Row>
          <hr />
          <Button
            variant='secondary'
            onClick={() => {
              showLabTypeModal(false);
            }}
            className='ml-2'
          >
            Close
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return { user: state.user };
};
export default connect(mapStateToProps)(LabTypeSetting);
