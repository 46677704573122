import axios from 'axios';
const BASE_URL = process.env.REACT_APP_API_URL;
const token = localStorage.getItem('ph-token-console');
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

//Dashboard Screen Api's

const getDashboardData = async (url) => {
  try {
    const URL = BASE_URL.concat(url);
    const data = await axios.get(URL);
    return data;
  } catch (err) {
    return err;
  }
};

//Shipper Screen Api's

const getAllShippers = async (url) => {
  try {
    const URL = BASE_URL.concat(url);
    const data = await axios.get(URL);
    return data;
  } catch (err) {
    return err;
  }
};

const getShippersById = async (id) => {
  try {
    const URL = BASE_URL.concat(`shipper/${id}`);
    const data = await axios.get(URL);
    return data;
  } catch (err) {
    return err;
  }
};

const getShippersSettingsById = async (id) => {
  try {
    const URL = BASE_URL.concat(`shipper/${id}/settings`);
    const data = await axios.get(URL);
    return data;
  } catch (err) {
    return err;
  }
};

const deleteShipperSettingById = async (shipperId, id) => {
  try {
    const URL = BASE_URL.concat(`shipper/${shipperId}/settings/${id}`);
    const data = await axios.delete(URL);
    return data;
  } catch (err) {
    return err;
  }
};

const getDeliveryTimeSlotById = async (id) => {
  try {
    const URL = BASE_URL.concat(`shipper/${id}/timeslots`);
    const data = await axios.get(URL);
    return data;
  } catch (err) {
    return err;
  }
};

const updateShippersTimeSlotSettingById = async (shipperId, id, settings) => {
  try {
    const URL = BASE_URL.concat(`shipper/${shipperId}/timeslots/${id}`);
    const data = await axios.post(URL, settings);
    return data;
  } catch (err) {
    return err;
  }
};

const addShippersTimeSlotSetting = async (id, settings) => {
  try {
    const URL = BASE_URL.concat(`shipper/${id}/timeslots`);
    const data = await axios.post(URL, settings);
    return data;
  } catch (err) {
    return err;
  }
};

const deleteShipperTimeSlotSettingById = async (shipperId, id) => {
  try {
    const URL = BASE_URL.concat(`shipper/${shipperId}/timeslots/${id}`);
    const data = await axios.delete(URL);
    return data;
  } catch (err) {
    return err;
  }
};

const getShippersDefaultSettings = async () => {
  try {
    const URL = BASE_URL.concat(`shipper/default/settings`);
    const data = await axios.get(URL);
    return data;
  } catch (err) {
    return err;
  }
};

const addShippersSettings = async (id, settings) => {
  try {
    const URL = BASE_URL.concat(`shipper/${id}/settings`);
    const data = await axios.post(URL, settings);
    return data;
  } catch (err) {
    return err;
  }
};

const updateShippersSettingsById = async (shipperId, id, settings) => {
  try {
    const URL = BASE_URL.concat(`shipper/${shipperId}/settings/${id}`);
    const data = await axios.post(URL, settings);
    return data;
  } catch (err) {
    return err;
  }
};

const getShippersUsersById = async (id) => {
  try {
    const URL = BASE_URL.concat(`shipper/${id}/users`);
    const data = await axios.get(URL);
    return data;
  } catch (err) {
    return err;
  }
};

const sendInvitationLink = async (id,userId,request) => {
  try {
    const URL = BASE_URL.concat(`shipper/${id}/users/${userId}/invite`);
    const data = await axios.post(URL,request);
    return data;
  } catch (err) {
    return err;
  }
};

const sendInvitationSMS = async (id,userId,request) => {
  try {
    const URL = BASE_URL.concat(`carrier/${id}/users/${userId}/invite`);
    const data = await axios.post(URL,request);
    return data;
  } catch (err) {
    return err;
  }
};


const addEditShipper = async (url, shipper) => {
  try {
    const URL = BASE_URL.concat(url);
    const data = await axios.post(URL, shipper);
    return data;
  } catch (err) {
    return err;
  }
};

const updateShipperUserById = async (id, userId, shipper) => {
  try {
    const URL = BASE_URL.concat(`shipper/${id}/users/${userId}`);
    const data = await axios.post(URL, shipper);
    return data;
  } catch (err) {
    return err;
  }
};

const updateUserRoleById = async (id, userId, role) => {
  try {
    const URL = BASE_URL.concat(`shipper/${id}/users/${userId}`);
    const data = await axios.post(URL, role);
    return data;
  } catch (err) {
    return err;
  }
};

const updateUserById = async (id, userId, request) => {
  try {
    const URL = BASE_URL.concat(`shipper/${id}/users/${userId}/update`);
    const data = await axios.post(URL, request);
    return data;
  } catch (err) {
    return err;
  }
};

const deleteShipperById = async (id) => {
  try {
    const URL = BASE_URL.concat(`shipper/${id}`);
    const data = await axios.delete(URL);
    return data;
  } catch (err) {
    return err;
  }
};

const deleteShipperUserById = async (id, userId) => {
  try {
    const URL = BASE_URL.concat(`shipper/${id}/users/${userId}`);
    const data = await axios.delete(URL);
    return data;
  } catch (err) {
    return err;
  }
};

//Shipment Screen Api's

const getShipmentData = async (url) => {
  try {
    const URL = BASE_URL.concat(url);
    const data = await axios.get(URL);
    return data;
  } catch (err) {
    return err;
  }
};

const updateShipmentById = async (id, shipment) => {
  try {
    const URL = BASE_URL.concat(`shipment/${id}`);
    const data = await axios.post(URL, shipment);
    return data;
  } catch (err) {
    return err;
  }
};

const getFeedbackById = async (id) => {
  try {
    const URL = BASE_URL.concat(`shipment/${id}/feedback`);
    const data = await axios.get(URL);
    return data;
  } catch (err) {
    return err;
  }
};
const updateShipmentStatusById = async (url, shipment) => {
  try {
    const URL = BASE_URL.concat(url);
    const data = await axios.post(URL, shipment);
    return data;
  } catch (err) {
    return err;
  }
};

const getShipmentsById = async (id) => {
  try {
    const URL = BASE_URL.concat(`shipment/${id}`);
    const data = await axios.get(URL);
    return data;
  } catch (err) {
    return err;
  }
};

const getHistoryById = async (id) => {
  try {
    const URL = BASE_URL.concat(`shipment/${id}/status`);
    const data = await axios.get(URL);
    return data;
  } catch (err) {
    return err;
  }
};

const getCarriersList = async () => {
  try {
    const URL = BASE_URL.concat('carrier');
    const data = await axios.get(URL);
    return data;
  } catch (err) {
    return err;
  }
};

const getCarrierTimeSlotById = async (id) => {
  try {
    const URL = BASE_URL.concat(`carrier/${id}/timeslots`);
    const data = await axios.get(URL);
    return data;
  } catch (err) {
    return err;
  }
};

const updateCarrierShipmentById = async (id, carrierId, request) => {
  try {
    const URL = BASE_URL.concat(`shipment/${id}/carrier/${carrierId}`);
    const data = await axios.post(URL, request);
    return data;
  } catch (err) {
    return err;
  }
};

//Carrier Screen Api's

const getCarrierById = async (id) => {
  try {
    const URL = BASE_URL.concat(`carrier/${id}`);
    const data = await axios.get(URL);
    return data;
  } catch (err) {
    return err;
  }
};

const getDefaultSetting = async () => {
  try {
    const URL = BASE_URL.concat(`carrier/default/settings`);
    const data = await axios.get(URL);
    return data;
  } catch (err) {
    return err;
  }
};

const getCarrierSettingsById = async (id) => {
  try {
    const URL = BASE_URL.concat(`carrier/${id}/settings`);
    const data = await axios.get(URL);
    return data;
  } catch (err) {
    return err;
  }
};

const getUsersByCarrierId = async (id) => {
  try {
    const URL = BASE_URL.concat(`carrier/${id}/users`);
    const data = await axios.get(URL);
    return data;
  } catch (err) {
    return err;
  }
};

const addEditCarrier = async (url, carrier) => {
  try {
    const URL = BASE_URL.concat(url);
    const data = await axios.post(URL, carrier);
    return data;
  } catch (err) {
    return err;
  }
};

const updateCarrierUserById = async (carrierId, userId, carrier) => {
  try {
    const URL = BASE_URL.concat(`carrier/${carrierId}/users/${userId}`);
    const data = await axios.post(URL, carrier);
    return data;
  } catch (err) {
    return err;
  }
};

const editCarrierUserById = async (carrierId, userId, carrier) => {
  try {
    const URL = BASE_URL.concat(`carrier/${carrierId}/users/${userId}/update`);
    const data = await axios.post(URL, carrier);
    return data;
  } catch (err) {
    return err;
  }
};

const getUserByEmail = async (email) => {
  try {
    const URL = BASE_URL.concat(`user/${email}`);
    const data = await axios.get(URL);
    return data;
  } catch (err) {
    return err;
  }
};

const getUserByPhone = async (phone) => {
  try {
    const URL = BASE_URL.concat(`user/${phone}`);
    const data = await axios.get(URL);
    return data;
  } catch (err) {
    return err;
  }
};

const createUser = async (user) => {
  try {
    const URL = BASE_URL.concat('user');
    const data = await axios.post(URL, user);
    return data;
  } catch (err) {
    return err;
  }
};

const updateUserPassword = async (id, password) => {
  try {
    const URL = BASE_URL.concat(`user/${id}/password`);
    const data = await axios.post(URL, password);
    return data;
  } catch (err) {
    return err;
  }
};

const deleteCarrierUserById = async (carrierId, id) => {
  try {
    const URL = BASE_URL.concat(`carrier/${carrierId}/users/${id}`);
    const data = await axios.delete(URL);
    return data;
  } catch (err) {
    return err;
  }
};

const deleteCarrierById = async (id) => {
  try {
    const URL = BASE_URL.concat(`carrier/${id}`);
    const data = await axios.delete(URL);
    return data;
  } catch (err) {
    return err;
  }
};

const getAllCarriers = async (url) => {
  try {
    const URL = BASE_URL.concat(url);
    const data = await axios.get(URL);
    return data;
  } catch (err) {
    return err;
  }
};

const addCarrierSetting = async (id, settings) => {
  try {
    const URL = BASE_URL.concat(`carrier/${id}/settings`);
    const data = await axios.post(URL, settings);
    return data;
  } catch (err) {
    return err;
  }
};

const updateSettingById = async (carrierId, id, settings) => {
  try {
    const URL = BASE_URL.concat(`carrier/${carrierId}/settings/${id}`);
    const data = await axios.post(URL, settings);
    return data;
  } catch (err) {
    return err;
  }
};

const deleteSettingById = async (carrierId, id) => {
  try {
    const URL = BASE_URL.concat(`carrier/${carrierId}/settings/${id}`);
    const data = await axios.delete(URL);
    return data;
  } catch (err) {
    return err;
  }
};

const updateCarrierTimeSlotSettingById = async (shipperId, id, settings) => {
  try {
    const URL = BASE_URL.concat(`carrier/${id}/timeslots/${id}`);
    const data = await axios.post(URL, settings);
    return data;
  } catch (err) {
    return err;
  }
};

const addCarrierTimeSlotSetting = async (id, settings) => {
  try {
    const URL = BASE_URL.concat(`carrier/${id}/timeslots`);
    const data = await axios.post(URL, settings);
    return data;
  } catch (err) {
    return err;
  }
};

const deleteCarrierTimeSlotSettingById = async (carrierId, id) => {
  try {
    const URL = BASE_URL.concat(`carrier/${carrierId}/timeslots/${id}`);
    const data = await axios.delete(URL);
    return data;
  } catch (err) {
    return err;
  }
};

const getShippersByGroup = async (name) => {
  try {
    const URL = BASE_URL.concat(`shipper?group_name=${name}`);
    const data = await axios.get(URL);
    return data;
  } catch (err) {
    return err;
  }
};

const getAllGroups = async () => {
  try {
    const URL = BASE_URL.concat(`shipper/group/all`);
    const data = await axios.get(URL);
    return data;
  } catch (err) {
    return err;
  }
};

const refreshToken = async () => {
  try {
    const URL = BASE_URL.concat('auth/refresh');
    const data = await axios.post(URL);
    return data;
  } catch (err) {
    return err;
  }
};

const getDefaultDeliveryTimeSlot = async () => {
  try {
    const URL = BASE_URL.concat(`shipper/default/timeslots`);
    const data = await axios.get(URL);
    return data;
  } catch (err) {
    return err;
  }
};

const getDefaultCarrierTimeSlot = async () => {
  try {
    const URL = BASE_URL.concat(`carrier/default/timeslots`);
    const data = await axios.get(URL);
    return data;
  } catch (err) {
    return err;
  }
};

const getDefaultStoargeType = async () => {
  try {
    const URL = BASE_URL.concat(`shipper/default/storage-type`);
    const data = await axios.get(URL);
    return data;
  } catch (err) {
    return err;
  }
};

const getDefaultServiceType = async () => {
  try {
    const URL = BASE_URL.concat(`shipper/default/service-type`);
    const data = await axios.get(URL);
    return data;
  } catch (err) {
    return err;
  }
};

const getDefaultLabType = async () => {
  try {
    const URL = BASE_URL.concat(`shipper/default/product-type`);
    const data = await axios.get(URL);
    return data;
  } catch (err) {
    return err;
  }
};

const getStoargeTypeById = async (id) => {
  try {
    const URL = BASE_URL.concat(`shipper/${id}/storage-type`);
    const data = await axios.get(URL);
    return data;
  } catch (err) {
    return err;
  }
};

const getServiceTypeById = async (id) => {
  try {
    const URL = BASE_URL.concat(`shipper/${id}/service-type`);
    const data = await axios.get(URL);
    return data;
  } catch (err) {
    return err;
  }
};

const getLabTypeById = async (id) => {
  try {
    const URL = BASE_URL.concat(`shipper/${id}/product-type`);
    const data = await axios.get(URL);
    return data;
  } catch (err) {
    return err;
  }
};

const deleteShipperStorageSettingById = async (shipperId, id) => {
  try {
    const URL = BASE_URL.concat(`shipper/${shipperId}/storage-type/${id}`);
    const data = await axios.delete(URL);
    return data;
  } catch (err) {
    return err;
  }
};

const deleteShipperServiceSettingById = async (shipperId, id) => {
  try {
    const URL = BASE_URL.concat(`shipper/${shipperId}/service-type/${id}`);
    const data = await axios.delete(URL);
    return data;
  } catch (err) {
    return err;
  }
};

const deleteLabSettingById = async (shipperId, id) => {
  try {
    const URL = BASE_URL.concat(`shipper/${shipperId}/product-type/${id}`);
    const data = await axios.delete(URL);
    return data;
  } catch (err) {
    return err;
  }
};

const updateShippersStorageSettingById = async (shipperId, id, settings) => {
  try {
    const URL = BASE_URL.concat(`shipper/${shipperId}/storage-type/${id}`);
    const data = await axios.post(URL, settings);
    return data;
  } catch (err) {
    return err;
  }
};

const updateShippersServiceSettingById = async (shipperId, id, settings) => {
  try {
    const URL = BASE_URL.concat(`shipper/${shipperId}/service-type/${id}`);
    const data = await axios.post(URL, settings);
    return data;
  } catch (err) {
    return err;
  }
};

const updateLabSettingById = async (shipperId, id, settings) => {
  try {
    const URL = BASE_URL.concat(`shipper/${shipperId}/product-type/${id}`);
    const data = await axios.post(URL, settings);
    return data;
  } catch (err) {
    return err;
  }
};

const addShippersStorageTypeSetting = async (id, settings) => {
  try {
    const URL = BASE_URL.concat(`shipper/${id}/storage-type`);
    const data = await axios.post(URL, settings);
    return data;
  } catch (err) {
    return err;
  }
};

const addShippersServiceTypeSetting = async (id, settings) => {
  try {
    const URL = BASE_URL.concat(`shipper/${id}/service-type`);
    const data = await axios.post(URL, settings);
    return data;
  } catch (err) {
    return err;
  }
};

const addLabTypeSetting = async (id, settings) => {
  try {
    const URL = BASE_URL.concat(`shipper/${id}/product-type`);
    const data = await axios.post(URL, settings);
    return data;
  } catch (err) {
    return err;
  }
};

const inviteUsers = async (user, userId, shipperIds) => {
  try {
    const URL = BASE_URL.concat(`shipper/users/${userId}/add?shipper=${shipperIds}`);
    const data = await axios.post(URL, user);
    return data;
  } catch (err) {
    return err;
  }
};

const shipmentStatusList= async () => {
  try {
    const URL = BASE_URL.concat(`shipment/status/all`);
    const data = await axios.get(URL);
    return data;
  } catch (err) {
    return err;
  }
};

const getPatientTrackingUrlById = async (id) => {
  try {
    const URL = BASE_URL.concat(`shipper/${id}/settings?key=url_tracking_portal`);
    const data = await axios.get(URL);
    return data;
  } catch (err) {
    return err;
  }
}

const getPatientTrackingUrl = async () => {
  try {
    const URL = BASE_URL.concat(`shipper/default/settings?key=url_tracking_portal`);
    const data = await axios.get(URL);
    return data;
  } catch (err) {
    return err;
  }
}

const getDistance = async (location) => {
  try {
    const URL = BASE_URL.concat(`shipment/distance/miles`);
    const data = await axios.post(URL,location);
    return data;
  } catch (err) {
    return err;
  }
};

const checkBatchShipment = async (batch_number,currentShipmentStatus) => {
  try {
    const URL = BASE_URL.concat(`shipment?batch_number=${batch_number}&status=${currentShipmentStatus}`);
    const data = await axios.get(URL);
    return data;
  } catch (err) {
    return err;
  }
};

const updateShipmentDriverById = async (id, driverId, request) => {
  try {
    const URL = BASE_URL.concat(`shipment/${id}/driver/${driverId}`);
    const data = await axios.post(URL, request);
    return data;
  } catch (err) {
    return err;
  }
};

const getShipperTimeZone = async (request) => {
  try {
    const URL = BASE_URL.concat(`shipper/location/time-zone`);
    const data = await axios.post(URL, request);
    return data;
  } catch (err) {
    return err;
  }
};

const uploadImgInFirebase = async (req) => {
  try {
    const URL = BASE_URL.concat(`file`);
    console.log('request', req);
    const data = await axios.post(URL, req, {
      headers: {
        'Content-Type': req.mimetype
      }
    });
    return data;
  } catch (err) {
    return err;
  }
}


const addShipmentPODAttachments = async (attachments, shipment_id) => {
  try {
    const URL = BASE_URL.concat(`shipment-status-history/${shipment_id}/shipment-pod`);
    const data = await axios.post(URL, attachments);
    return data;
  } catch (err) {
    return err;
  }
}




export {getUserByPhone,getShipperTimeZone,updateShipmentDriverById,checkBatchShipment, getDistance,getPatientTrackingUrl, getPatientTrackingUrlById, shipmentStatusList, inviteUsers, updateUserById, editCarrierUserById, updateShippersServiceSettingById, addShippersServiceTypeSetting, deleteShipperServiceSettingById, getServiceTypeById, getDefaultServiceType, getDashboardData, updateLabSettingById, addLabTypeSetting, deleteLabSettingById, getDefaultLabType, getLabTypeById, addShippersStorageTypeSetting, updateShippersStorageSettingById, deleteShipperStorageSettingById, getDefaultStoargeType, getStoargeTypeById, getDefaultCarrierTimeSlot, getDefaultDeliveryTimeSlot, addCarrierTimeSlotSetting, deleteCarrierTimeSlotSettingById, updateCarrierTimeSlotSettingById, getCarrierTimeSlotById, addCarrierSetting, addShippersSettings, addShippersTimeSlotSetting, updateShippersTimeSlotSettingById, deleteShipperTimeSlotSettingById, getAllGroups, getDeliveryTimeSlotById, getShippersByGroup, addEditShipper, getFeedbackById, refreshToken, deleteShipperSettingById, updateShippersSettingsById, getShippersDefaultSettings, getShippersSettingsById, getAllShippers, getShipmentData, deleteShipperUserById, updateUserRoleById, updateShipperUserById, getShippersUsersById, getShippersById, deleteShipperById, updateShipmentById, updateShipmentStatusById, getHistoryById, getCarriersList, updateCarrierShipmentById, getShipmentsById, createUser, updateSettingById, deleteSettingById, getDefaultSetting, getCarrierSettingsById, getAllCarriers, getCarrierById, deleteCarrierById, deleteCarrierUserById, updateUserPassword, addEditCarrier, getUserByEmail, getUsersByCarrierId, updateCarrierUserById,sendInvitationLink,sendInvitationSMS,uploadImgInFirebase,addShipmentPODAttachments };
