import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';
import swal from 'sweetalert';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { toast } from 'react-toastify';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import { updateToken } from '../../Utils';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Spinner from '../../components/Spinner';
import Container from 'react-bootstrap/Container';
import { addShippersSettings, getShippersSettingsById, deleteShipperSettingById, updateShippersSettingsById, getShippersDefaultSettings, getCarriersList, getCarrierById } from '../../services/index';

const Printers = [
  {
    name: `Default`,
    id: `default_printer`,
  },
  {
    name: `Zebra Laser`,
    id: `zebra_laser_printer`,
  },
  {
    name: `Zebra ZP 505`,
    id: `zebra_zp_505_printer`,
  },
];

const OrderNumberProvision = [
  {
    name: `Mandatory`,
    id: true,
  },
  {
    name: `Non-Mandatory`,
    id: false,
  },
];

const GeneralSettings = (props) => {
  //configuration for distance
  const distanceSettingKey = 'shipper_maximum_delivery_distance';

  //configuration for carriers
  const carrierSettingKey = 'shipper_default_carrier';

  //configuration for printer
  const printerSettingKey = 'shipper_default_printer';

  //configuration for orderNumber
  const orderNumberSettingKey = 'shipper_order_number';

  const settingSchema = Yup.object().shape({
    value: Yup.string().required('Value is required.') || Yup.number().required('Value is required.'),
  });

  const shipperId = props.shipperId;
  const [loading, showSpinner] = useState(false);
  const [carriers, setCarriers] = useState({});
  const [shipperSetting, setShipperSetting] = useState({});
  const [defaultSettings, setDefaultSettings] = useState([]);
  const [shipperSettings, setShipperSettings] = useState([]);
  const [showAddSettingModal, setShowAddSettingModal] = useState(false);
  const [showChangeSettingModal, setShowChangeSettingModal] = useState(false);

  useEffect(() => {
    getDefaultSettings();
    getShipperSettings();
    getCarriers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getShipperSettings = async () => {
    showSpinner(true);
    const response = await getShippersSettingsById(shipperId);
    if (response) {
      if (response.status === 200 || response.status === 201) {
        let shipperSettings = response?.data;
        const carrierSetting = shipperSettings.find((item) => item.key === carrierSettingKey);
        let carrierName = '';
        if (!isEmpty(carrierSetting) && carrierSetting?.value) {
          carrierName = await getCarrier(carrierSetting?.value);
        }
        shipperSettings.map((setting) => {
          if (setting.key === carrierSettingKey) {
            setting.value = carrierName;
            return setting;
          }
          return setting;
        });
        setShipperSettings(response?.data);
        showSpinner(false);
      } else {
        if (response.response.status === 401 || response.response.data === 'Unauthorized') {
          await updateToken();
        }
        if (response.response.status >= 500) {
          toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
        }
        showSpinner(false);
      }
    }
  };

  const getCarrier = async (carrierId) => {
    showSpinner(true);
    const response = await getCarrierById(carrierId);
    if (response) {
      if (response.status === 200 || response.status === 201) {
        showSpinner(false);
        return response?.data?.name;
      } else {
        if (response.response.status === 401 || response.response.data === 'Unauthorized') {
          await updateToken();
        }
        if (response.response.status >= 500) {
          toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
        }
        showSpinner(false);
      }
    }
  };

  const getCarriers = async () => {
    showSpinner(true);
    const response = await getCarriersList();
    if (response) {
      if (response.status === 200 || response.status === 201) {
        setCarriers(response?.data);
        showSpinner(false);
      } else {
        if (response.response.status === 401 || response.response.data === 'Unauthorized') {
          await updateToken();
        }
        if (response.response.status >= 500) {
          toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
        }
        showSpinner(false);
      }
    }
  };

  const getDefaultSettings = async () => {
    showSpinner(true);
    const response = await getShippersDefaultSettings();
    if (response) {
      if (response.status === 200 || response.status === 201) {
        setDefaultSettings(response?.data);
        showSpinner(false);
      } else {
        if (response.response.status === 401 || response.response.data === 'Unauthorized') {
          await updateToken();
        }
        if (response.response.status >= 500) {
          toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
        }
        showSpinner(false);
      }
    }
  };

  const addSettingModal = () => {
    setShowAddSettingModal(true);
  };

  const hideAddSettingModal = () => {
    setShowAddSettingModal(false);
  };

  const changeSettingModal = (shipperSetting) => {
    setShipperSetting(shipperSetting);
    setShowChangeSettingModal(true);
  };

  const hideChangeSettingModal = () => {
    setShowChangeSettingModal(false);
  };

  const handleRemove = async (setting) => {
    swal({
      title: `Remove ${setting.name}`,
      text: `Are you sure that you want to remove ${setting.name}?`,
      buttons: ['Cancel', 'Yes'],
      icon: 'error',
      dangerMode: true,
    }).then(async (status) => {
      if (status) {
        showSpinner(true);
        const response = await deleteShipperSettingById(shipperId, setting.id);
        if (response) {
          if (response.status === 200 || response.status === 201) {
            getShipperSettings();
            showSpinner(false);
            toast.success(`${setting.name} has been removed.`);
          } else {
            if (response.response.status === 401 || response.response.data === 'Unauthorized') {
              await updateToken();
            }
            if (response.response.status >= 500) {
              toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
            }
            showSpinner(false);
          }
        }
      }
    });
  };

  const handleSubmit = async (data, isEdit) => {
    hideAddSettingModal();
    hideChangeSettingModal();
    const setting = defaultSettings.filter((x) => x.key === data.key)[0];
    const custom_setting = {};
    if (isEdit) {
      custom_setting.value = data.value;
      custom_setting.key = data.key;
      custom_setting.name = data.name;
    } else {
      custom_setting.key = setting.key;
      custom_setting.name = setting.name;
      custom_setting.value = data.value;
    }
    showSpinner(true);
    const response = isEdit ? await updateShippersSettingsById(shipperId, data.id, custom_setting) : await addShippersSettings(shipperId, custom_setting);
    if (response) {
      if (response.status === 200 || response.status === 201) {
        getShipperSettings();
        showSpinner(false);
        toast.success(`${setting.name} has been updated.`);
      } else {
        if (response.response.status === 401 || response.response.data === 'Unauthorized') {
          await updateToken();
        }
        if (response.response.status >= 500) {
          toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
        }
        showSpinner(false);
      }
    }
  };

  return (
    <>
      <Container>
        <Card>
          <Card.Header>
            <Row>
              <Col></Col>
              <Col sm="auto" className="p-0 text-right">
                <Button variant="white" onClick={addSettingModal}>
                  Add Setting
                </Button>
              </Col>
            </Row>
          </Card.Header>
          <Spinner display={loading}>
            <Table responsive size="sm">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Value</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {shipperSettings &&
                  shipperSettings.map((setting, index) => {
                    return (
                      <tr key={index}>
                        <td>{setting.name}</td>
                        <td>{setting.value}</td>
                        <td className="text-right">
                          <div className="dropdown">
                            <a href="/#" className="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <i className="fe fe-more-vertical"></i>
                            </a>
                            <div className="dropdown-menu dropdown-menu-right">
                              <button
                                className="dropdown-item"
                                onClick={() => {
                                  changeSettingModal(setting);
                                }}
                              >
                                Change
                              </button>
                              <button
                                className="dropdown-item"
                                onClick={() => {
                                  handleRemove(setting);
                                }}
                              >
                                Remove
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </Spinner>
        </Card>
      </Container>

      <Modal show={showAddSettingModal} onHide={hideAddSettingModal}>
        <Modal.Body>
          <Modal.Title className="h1">Add Setting</Modal.Title>
          <hr />
          <Formik initialValues={shipperSetting} validationSchema={settingSchema} onSubmit={(data) => handleSubmit(data, false)}>
            {({ handleChange, handleSubmit, values, errors }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group>
                  <Form.Control as="select" name="key" custom value={values.key} onChange={handleChange}>
                    <option value="-">Select Setting</option>
                    {defaultSettings &&
                      defaultSettings.map((setting, index) => {
                        return (
                          <option key={index} value={setting.key}>
                            {setting.name}
                          </option>
                        );
                      })}
                  </Form.Control>
                </Form.Group>
                {values.key === distanceSettingKey ? (
                  <Form.Group>
                    <Form.Label>
                      Set Maximum delivery distance<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control placeholder="Please enter the distance in miles" type="number" min="0" name="value" value={values.value} onChange={handleChange} isInvalid={!!errors.value} />
                    <Form.Control.Feedback type="invalid">{errors.value}</Form.Control.Feedback>
                  </Form.Group>
                ) : values.key === carrierSettingKey ? (
                  <Form.Group>
                    <Form.Control as="select" name="value" custom value={values.value} onChange={handleChange}>
                      <option value="-">-- Select Courier --</option>
                      {carriers.map((carrier, index) => {
                        return (
                          <option value={carrier.id} key={index}>
                            {carrier.name}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Form.Group>
                ) : values.key === printerSettingKey ? (
                  <Form.Group>
                    <Form.Control as="select" name="value" custom value={values.value} onChange={handleChange}>
                      <option value="-">-- Select Printer --</option>
                      {Printers.map((type) => {
                        return (
                          <option value={type.name} key={type.id}>
                            {type.name}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Form.Group>
                ) : values.key === orderNumberSettingKey ? (
                  <Form.Group>
                    <Form.Control as="select" name="value" custom value={values.value} onChange={handleChange}>
                      <option value="-">-- Select --</option>
                      {OrderNumberProvision.map((type) => {
                        return (
                          <option value={type.id} key={type.id}>
                            {type.name}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Form.Group>
                ) : (
                  <Form.Group>
                    <Form.Control as="textarea" rows="5" name="value" value={values.value} onChange={handleChange} isInvalid={!!errors.value} />
                    <Form.Control.Feedback type="invalid">{errors.value}</Form.Control.Feedback>
                  </Form.Group>
                )}
                <Button type="submit" variant="primary">
                  Add Setting
                </Button>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>

      <Modal show={showChangeSettingModal} onHide={hideChangeSettingModal}>
        <Modal.Body>
          <Modal.Title className="h1 mb-0">{shipperSetting.name}</Modal.Title>
          <hr />

          <Formik initialValues={shipperSetting} validationSchema={settingSchema} onSubmit={(data) => handleSubmit(data, true)}>
            {({ handleChange, handleSubmit, values, errors }) => (
              <Form noValidate onSubmit={handleSubmit}>
                {values.key === distanceSettingKey ? (
                  <Form.Group>
                    <Form.Label>
                      Set Maximum delivery distance<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control placeholder="Please enter the distance in miles" type="number" min="0" name="value" value={values.value} onChange={handleChange} isInvalid={!!errors.value} />
                    <Form.Control.Feedback type="invalid">{errors.value}</Form.Control.Feedback>
                  </Form.Group>
                ) : values.key === carrierSettingKey ? (
                  <Form.Group>
                    <Form.Control as="select" name="value" custom value={values.value} onChange={handleChange}>
                      <option value="-">-- Select Courier --</option>
                      {carriers.map((carrier, index) => {
                        return (
                          <option value={carrier.id} key={index}>
                            {carrier.name}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Form.Group>
                ) : values.key === printerSettingKey ? (
                  <Form.Group>
                    <Form.Control as="select" name="value" custom value={values.value} onChange={handleChange}>
                      <option value="-">-- Select Printer --</option>
                      {Printers.map((type) => {
                        return (
                          <option value={type.name} key={type.id}>
                            {type.name}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Form.Group>
                ) : values.key === orderNumberSettingKey ? (
                  <Form.Group>
                    <Form.Control as="select" name="value" custom value={values.value} onChange={handleChange}>
                      <option value="-">-- Select --</option>
                      {OrderNumberProvision.map((type) => {
                        return (
                          <option value={type.id} key={type.id}>
                            {type.name}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Form.Group>
                ) : (
                  <Form.Group>
                    <Form.Control as="textarea" rows="5" name="value" value={values.value} onChange={handleChange} isInvalid={!!errors.value} />
                    <Form.Control.Feedback type="invalid">{errors.value}</Form.Control.Feedback>
                  </Form.Group>
                )}
                <Button type="submit" variant="primary">
                  Update
                </Button>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return { user: state.user };
};
export default connect(mapStateToProps)(GeneralSettings);
