import React, { Component } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { connect } from 'react-redux';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { updateToken } from '../../Utils';
import Button from 'react-bootstrap/Button';
import Navbar from '../../components/Navigation';
import Header from '../../components/Header';
import Spinner from '../../components/Spinner';
import Container from 'react-bootstrap/Container';
import { getCarrierById, addEditCarrier } from '../../services/index';

const carrierSchema = Yup.object().shape({
  name: Yup.string().required('Name is required.'),
  email: Yup.string().email('Email is invalid.').required('Email is required.'),
  phone: Yup.string().matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, 'Please enter a valid phone number.'),
  fax: Yup.string().matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, 'Please enter a valid phone number.'),
  address1: Yup.string().required('Address1 is required.'),
  city: Yup.string().required('City is required.'),
  state: Yup.string().required('State is required.'),
  postal_code: Yup.string()
    .matches(/^[0-9]{5}(?:-[0-9]{4})?$/, 'Please enter a valid postal code.')
    .required('Postal Code is required.'),
});

class AddEditCarrier extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      carrierId: this.props.match.params.id,
      carrier: {
        name: '',
        alias: '',
        phone: '',
        fax: '',
        email: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        postal_code: '',
      },
    };
  }

  componentDidMount() {
    const { carrierId } = this.state;
    if (carrierId) {
      this.getCarrier();
    }
  }

  getCarrier = async () => {
    const { carrierId } = this.state;
    await this.setState({ loading: true });
    const response = await getCarrierById(carrierId);
    if (response) {
      if (response.status === 200 || response.status === 201) {
        let carrier = response?.data;
        carrier.address1 = carrier.address.address1;
        carrier.address2 = carrier.address.address2;
        carrier.city = carrier.address.city;
        carrier.state = carrier.address.state;
        carrier.postal_code = carrier.address.postal_code;
        await this.setState({ carrier, loading: false });
      } else {
        if (response.response.status === 401 || response.response?.data === 'Unauthorized') {
          await updateToken();
        }
        if (response.response.status >= 500) {
          toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
        }
        await this.setState({ loading: false });
      }
    }
  };

  handleSubmit = async (carrier) => {
    carrier.address = {
      address1: carrier.address1,
      address2: carrier.address2 || '',
      city: carrier.city,
      state: carrier.state,
      postal_code: carrier.postal_code,
    };
    carrier.email = carrier.email.toLowerCase().trim();
    await this.setState({ loading: true });
    const url = this.state.carrierId ? `carrier/${this.state.carrierId}` : 'carrier';
    const response = await addEditCarrier(url, carrier);
    if (response) {
      if (response.status === 200 || response.status === 201) {
        const message = this.state.carrierId ? 'Courier has been updated' : 'Courier has been added';
        this.props.history.push('/carrier');
        toast.success(message);
        this.props.history.push('/carrier');
      } else {
        if (response.response.status === 401 || response.response?.data === 'Unauthorized') {
          await updateToken();
        }
        if (response.response.status >= 500) {
          toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
        }
      }
    }
  };

  render() {
    const { carrierId, carrier, loading } = this.state;
    return (
      <>
        <Navbar />
        <div className='main-content'>
          <Spinner display={loading}>
            <Header title='Couriers' name={carrierId ? carrier.name : 'New Courier'}></Header>

            <Container>
              <Card>
                <Card.Body>
                  <Formik enableReinitialize={true} initialValues={this.state.carrier} validationSchema={carrierSchema} onSubmit={this.handleSubmit}>
                    {({ handleChange, handleSubmit, values, errors }) => (
                      <Form noValidate onSubmit={handleSubmit}>
                        <Form.Group>
                          <Form.Label>Name</Form.Label>
                          <Form.Control type='text' name='name' placeholder='e.g. ABC Company' value={values.name} onChange={handleChange} isInvalid={!!errors.name} />
                          <Form.Control.Feedback type='invalid'>{errors.name}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                          <Form.Label>Alias</Form.Label>
                          <Form.Control type='text' name='alias' placeholder='e.g. ABC Company' value={values.alias} onChange={handleChange} isInvalid={!!errors.alias} />
                          <Form.Control.Feedback type='invalid'>{errors.alias}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                          <Form.Label>Email</Form.Label>
                          <Form.Control type='email' name='email' placeholder='e.g. john@phoxhealth.com' value={values.email} onChange={handleChange} isInvalid={!!errors.email} />
                          <Form.Control.Feedback type='invalid'>{errors.email}</Form.Control.Feedback>
                        </Form.Group>

                        <Row>
                          <Col lg={6}>
                            <Form.Group>
                              <Form.Label>Phone</Form.Label>
                              <Form.Control type='phone' name='phone' placeholder='e.g. +1 301-123-4566' value={values.phone} onChange={handleChange} isInvalid={!!errors.phone} />
                              <Form.Control.Feedback type='invalid'>{errors.phone}</Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col lg={6}>
                            <Form.Group>
                              <Form.Label>Fax</Form.Label>
                              <Form.Control type='phone' name='fax' placeholder='e.g. +1 301-123-4566' value={values.fax} onChange={handleChange} isInvalid={!!errors.fax} />
                              <Form.Control.Feedback type='invalid'>{errors.fax}</Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>

                        <hr />

                        <Row>
                          <Col lg={6}>
                            <Form.Group>
                              <Form.Label>Address 1</Form.Label>
                              <Form.Control type='text' name='address1' placeholder='e.g. 100 Main St' value={values.address1} onChange={handleChange} isInvalid={!!errors.address1} />
                              <Form.Control.Feedback type='invalid'>{errors.address1}</Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col lg={6}>
                            <Form.Group>
                              <Form.Label>Address 2</Form.Label>
                              <Form.Control type='text' name='address2' placeholder='e.g. Suite 100' value={values.address2} onChange={handleChange} isInvalid={!!errors.address2} />
                              <Form.Control.Feedback type='invalid'>{errors.address2}</Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row>
                          <Col lg={4}>
                            <Form.Group>
                              <Form.Label>City</Form.Label>
                              <Form.Control type='text' name='city' placeholder='e.g. +1 301-123-4566' value={values.city} onChange={handleChange} isInvalid={!!errors.city} />
                              <Form.Control.Feedback type='invalid'>{errors.city}</Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col lg={4}>
                            <Form.Group>
                              <Form.Label>State</Form.Label>
                              <Form.Control type='text' name='state' placeholder='e.g. +1 301-123-4566' value={values.state} onChange={handleChange} isInvalid={!!errors.state} />
                              <Form.Control.Feedback type='invalid'>{errors.state}</Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col lg={4}>
                            <Form.Group>
                              <Form.Label>Postal Code</Form.Label>
                              <Form.Control type='text' name='postal_code' placeholder='e.g. +1 301-123-4566' value={values.postal_code} onChange={handleChange} isInvalid={!!errors.postal_code} />
                              <Form.Control.Feedback type='invalid'>{errors.postal_code}</Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                        <hr />
                        <Button type='submit' variant='primary'>
                          {this.props.match.params.id ? 'Update Courier' : 'Create Courier'}
                        </Button>
                        <Link to='/carrier' className='btn btn-white ml-2'>
                          Cancel
                        </Link>
                      </Form>
                    )}
                  </Formik>
                </Card.Body>
              </Card>
            </Container>
          </Spinner>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return { user: state.user };
};
export default connect(mapStateToProps)(AddEditCarrier);
