import { refreshToken } from './services/index';
import { isEmpty } from 'lodash';
import moment from 'moment';
import axios from 'axios';

let dayjs = require('dayjs');
let utc = require('dayjs/plugin/utc');
let timezone = require('dayjs/plugin/timezone');
let localizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);

export const sortData = (data) => {
  const sortedData = [...data];
  return sortedData.sort((a, b) => (a.cases > b.cases ? -1 : 1));
};

export const isValidEmail = (email) => {
  const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const result = pattern.test(email);
  return result;
};

export const isValidPhone = (phone) => {
  const pattern = /^\s*(?:\+?(\d{1,3}))?[- (]*(\d{3})[- )]*(\d{3})[- ]*(\d{4})(?: *[x/#]{1}(\d+))?\s*$/;
  const result = pattern.test(phone);
  return result;
};

export const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-20%',
    transform: 'translate(-50%, -50%)',
    width: '35%',
  },
};

export const customStylesModal = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-20%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
  },
};

export const toUtcDate = (date) => {
  if (!date) return '';
  return moment(date).utc().format('YYYY-MM-DDTHH:mm:ss');
};

export const toLocalDate = (utcDate, format) => {
  if (!utcDate) return '';
  return moment(utcDate).local().format(format);
};

export const toTimeZoneDate = (utcDate, format, timezone) => {
  if (!utcDate) return '';
  if (isEmpty(timezone)) {
    if (process.env.REACT_APP_ENV !== 'PROD') {
      return `${dayjs(utcDate).tz("Asia/Calcutta").format(format)} ${'IST'}`;
    } else {
      return `${dayjs(utcDate).tz("America/Los_Angeles").format(format)} ${'PST'}`;
    }
  } else {
     return `${dayjs(utcDate).tz(timezone?.timezone_id).format(format)} ${timezone?.timezone_alias}`;
  }
};

//for calcualtion purpose without timezone name
export const toTimeZone = (utcDate, format, timezone) => {
  if (!utcDate) return '';
  if (isEmpty(timezone)) {
    if (process.env.REACT_APP_ENV !== 'PROD') {
      return dayjs(utcDate).tz("Asia/Calcutta").format(format);
    } else {
      return dayjs(utcDate).tz("America/Los_Angeles").format(format);
    }
  } else {
    return dayjs(utcDate).tz(timezone?.timezone_id).format(format);
  }
};

export const toTimezoneConversion=(date,timezone)=>{
  if (!date) return '';
  const formatedDate=moment(date).format('YYYY-MM-DD HH:mm:ss');
  if (isEmpty(timezone)) {
    if (process.env.REACT_APP_ENV !== 'PROD') {
      return moment.tz(formatedDate, "YYYY-MM-DD HH:mm:ss", 'Asia/Calcutta').toISOString();
    } else {
      return moment.tz(formatedDate, "YYYY-MM-DD HH:mm:ss", 'America/Los_Angeles').toISOString();
    }
  } else {
    return moment.tz(formatedDate, "YYYY-MM-DD HH:mm:ss", timezone?.timezone_id).toISOString();
  }
}

export const getDateTimeDiffernce = (futureDateTime, pastDateTime) => {
  if (!futureDateTime || !pastDateTime) {
    return '';
  }
  const differenceInMinutes = moment(futureDateTime).diff(moment(pastDateTime), 'minute');
  const hours = Math.floor(differenceInMinutes / 60);
  const minutes = differenceInMinutes % 60;
  const difference = hours > 0 && minutes <= 0 ? `${hours} hr` : hours <= 0 && minutes > 0 ? `${minutes} mins` : `${hours} hr, ${minutes} mins`;
  return difference;
};

//Turn Around Time
export const getTAT = (deliveryDateTime, createdDateTime) => {
  if (!deliveryDateTime || !createdDateTime) {
    return '';
  }
  return `${moment(deliveryDateTime).diff(moment(createdDateTime), 'minute')}`;
};

export const updateToken = async () => {
  const response = await refreshToken();
  if (response && response.status === 200 && response?.data) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${response.data?.token}`;
    await localStorage.setItem('ph-token-console', response.data?.token);
    window.location.reload();
  } else {
    window.location.href = '/';
  }
  return response;
};

export const arrayDiffByKey = (key, ...arrays) => {
  return [].concat(
    ...arrays.map((arr, i) => {
      const others = arrays.slice(0);
      others.splice(i, 1);
      const unique = [...new Set([].concat(...others))];
      return arr.filter((x) => !unique.some((y) => x[key] === y[key]));
    })
  );
};
