import React from 'react';

class Avatar extends React.Component {
    render() {
        const size = this.props.size || 'sm'
        const alt = this.props.alt || 'avatar';
        const url = this.props.image || '/img/default-user.png'

        return (
            <div className={`avatar avatar-${size}`}>
                <img className='avatar-img rounded-circle' alt={alt} src={url} />
            </div>
        );
    }
}

export default Avatar;