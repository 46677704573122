import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import swal from 'sweetalert';
import { Formik } from 'formik';
import { sortBy, filter } from 'lodash';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import { updateToken } from '../../Utils';
import { Table, Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Navbar from '../../components/Navigation';
import Header from '../../components/Header';
import Spinner from '../../components/Spinner';
import ImageGallery from 'react-image-gallery';
import Container from 'react-bootstrap/Container';
import { sendInvitationSMS, createUser, editCarrierUserById, deleteCarrierUserById, updateUserPassword, getCarrierById, getUserByPhone, getUsersByCarrierId, updateCarrierUserById } from '../../services/index';

const CarrierUsers = (props) => {
  const inviteUser = {
    name: '',
    email: '',
    title: '',
    phone: '',
    role: 'viewer',
  };

  const inviteUserSchema = Yup.object().shape({
    name: Yup.string().required('Name is required.'),
    phone: Yup.string().required('Phone is required.'),
  });

  const editUserSchema = Yup.object().shape({
    name: Yup.string().required('Name is required.'),
    phone: Yup.string().required('Phone is required.'),
  });

  const carrierId = props.match.params.id;
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState({});
  const [keyword, setKeyword] = useState('');
  const [carrier, setCarrier] = useState({});
  const [loading, showSpinner] = useState(false);
  const [filteredUsers, setfilteredUsers] = useState(false);
  const [showInviteModal, setInviteModal] = useState(false);
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const [modalEditUser, toggleEditUserModal] = useState(false);
  const [showProofModal, setShowProofModal] = useState(false);

  useEffect(() => {
    getCarrier();
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCarrier = async () => {
    showSpinner(true);
    const response = await getCarrierById(carrierId);
    if (response) {
      if (response.status === 200 || response.status === 201) {
        setCarrier(response?.data);
        showSpinner(false);
      } else {
        if (response.response.status === 401 || response.response.data === 'Unauthorized') {
          await updateToken();
        }
        if (response.response.status >= 500) {
          toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
        }
        showSpinner(false);
      }
    }
  };

  const getUsers = async () => {
    showSpinner(true);
    const response = await getUsersByCarrierId(carrierId);
    if (response) {
      if (response.status === 200 || response.status === 201) {
        let userData = response?.data;
        userData.map((user) => {
          let proofs = [];
          if (user?.id_proof?.driver_licence) {
            const licence = user?.id_proof?.driver_licence;
            proofs.push({ original: licence?.front, thumbnail: licence?.front });
            proofs.push({ original: licence?.back, thumbnail: licence?.back });
          }
          user.license_proofs = proofs;
          user.name = user.name?.toLowerCase() || '';
          return user;
        });
        userData = sortBy(response?.data, 'name');
        setUsers(userData);
        showSpinner(false);
      } else {
        if (response.response.status === 401 || response.response.data === 'Unauthorized') {
          await updateToken();
        }
        if (response.response.status >= 500) {
          toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
        }
        showSpinner(false);
      }
    }
  };

  const onSearch = ({ target: { value } }) => {
    const search = value.toLowerCase().replace(/\s/g, '').trim();
    setKeyword(value);
    const filteredUsers = !value
      ? false
      : filter(users, (item) => {
          return item.name.toLowerCase().replace(/\s/g, '').trim().includes(search.toLowerCase().replace(/\s/g, '').trim()) || item.email.toLowerCase().replace(/\s/g, '').trim().includes(search.toLowerCase().replace(/\s/g, '').trim());
        });
    setfilteredUsers(filteredUsers);
  };

  const inviteModal = () => {
    setInviteModal(true);
  };

  const hideInviteModal = () => {
    setInviteModal(false);
  };

  const handleInviteUser = async (user) => {
    user.name = user.name.trim();
    user.email = user.email.toLowerCase().trim();
    showSpinner(true);
    const response = await getUserByPhone(user.phone);
    if (response) {
      if (response.status === 200 || response.status === 201) {
        const dbUser = response?.data;
        const request = { role: user.role };
        const result = await updateCarrierUserById(carrierId, dbUser.id, request);
        if (result) {
          getUsers();
          hideInviteModal();
          toast.success(`${user.name} has been added as ${user.role}`);
        }
        showSpinner(false);
      } else {
        if (response && response.response.status === 404) {
          try {
            const settings = {
              app: {
                type: 'app',
                version: '0.0',
                platform: '',
              },
            };
            user = { ...user, settings };
            const response = await createUser(user);
            if ((response && response.status === 201) || response.status === 200) {
              const dbUser = response?.data;
              const request = { role: user.role };
              const result = await updateCarrierUserById(carrierId, dbUser.id, request);
              if (result) {
                getUsers();
                hideInviteModal();
                toast.success(`${user.name} has been invited.`);
              }
            } else if (response?.response?.status === 409 || response?.response?.data?.errorCode === 'phone_conflict') {
              toast.error(response?.response?.data?.error);
            }
          } catch (err) {
            console.log(err);
          }
        } else if (response.response.status === 401 || response.response.data === 'Unauthorized') {
          await updateToken();
        } else if (response.response.status >= 500) {
          toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
        }
        showSpinner(false);
      }
    }
  };

  const editUserModal = async (user) => {
    user.role = user.roles.carrier[carrierId];
    setUser(user);
    setShowEditUserModal(true);
  };

  const hideEditUserModal = () => {
    setUser({});
    setShowEditUserModal(false);
  };

  const handleEditUser = async (user) => {
    if (user.role) {
      hideEditUserModal();
      showSpinner(true);
      const request = {
        role: user?.role,
        phone: user?.phone,
        name: user?.name?.trim(),
        title: user?.title,
        email: user?.email?.toLowerCase()?.trim(),
      };
      const response = await editCarrierUserById(carrierId, user.id, request);
      if (response) {
        if (response.status === 200 || response.status === 201) {
          toast.success(`${user.name}'s details has been updated.`);
          await getUsers();
          showSpinner(false);
        } else {
          if (response.response.status === 401 || response.response.data === 'Unauthorized') {
            await updateToken();
          }
          if (response.response.status >= 500) {
            toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
          }
          showSpinner(false);
        }
      }
    }
  };

  const handleRemove = async (user) => {
    swal({
      title: `Remove ${user.name}`,
      text: `Are you sure that you want to remove ${user.name}?`,
      buttons: ['Cancel', 'Yes'],
      icon: 'error',
      dangerMode: true,
    }).then(async (status) => {
      if (status) {
        showSpinner(true);
        const response = await deleteCarrierUserById(carrierId, user.id);
        if (response) {
          if (response.status === 200 || response.status === 201) {
            getUsers();
            toast.success(`${user.name} has been removed.`);
            showSpinner(false);
          } else {
            if (response.response.status === 401 || response.response.data === 'Unauthorized') {
              await updateToken();
            }
            if (response.response.status >= 500) {
              toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
            }
            showSpinner(false);
          }
        }
      }
    });
  };

  const handleInvite = async (user) => {
    swal({
      title: `Resend Invitation`,
      text: `Are you sure that you want to resend the invitation to ${user.name}?`,
      buttons: ['Cancel', 'Yes'],
      icon: 'warning',
      dangerMode: true,
    }).then(async (status) => {
      if (status) {
        showSpinner(true);
        const request = {
          phone: user?.phone,
        };
        const response = await sendInvitationSMS(carrierId, user?.id, request);
        if (response) {
          if (response.status === 200 || response.status === 201) {
            getUsers();
            toast.success(`Invite has been sent to ${user.name}.`);
            showSpinner(false);
          } else {
            if (response.response.status === 401 || response.response.data === 'Unauthorized') {
              await updateToken();
            }
            if (response.response.status >= 500) {
              toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
            }
            showSpinner(false);
          }
        }
      }
    });
  };

  const showResetPasswordModal = async (user) => {
    user.password = '';
    setUser(user);
    toggleEditUserModal(true);
  };

  const toggleLicenseModal = (user) => {
    setUser(user);
    setShowProofModal(true);
  };

  const handleResetPassword = async (user) => {
    const request = { password: user.password };
    const response = await updateUserPassword(user.id, request);
    if (response) {
      toast.success(`${user.name}'s password has been updated.`);
    }
    toggleEditUserModal(false);
  };

  const usersList = filteredUsers ? filteredUsers : users;

  return (
    <>
      <Navbar />
      <div className="main-content">
        <Header title={carrier.name} name="Users">
          <Button variant="white" onClick={inviteModal}>
            Invite User
          </Button>
        </Header>

        <Container>
          <Card>
            <Card.Header>
              <form>
                <div className="input-group input-group-flush">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fa fa-search"></i>
                    </span>
                  </div>
                  <input className="list-search form-control" type="search" placeholder="Search by name, email" id="keyword" value={keyword} name="keyword" onChange={onSearch} />
                </div>
              </form>
            </Card.Header>
            <Spinner display={loading}>
              <Table responsive size="sm">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th className="text-center">Platform</th>
                    <th className="text-center">Location / Permissions</th>
                    <th className="text-center">Title / Company</th>
                    <th className="text-center">Role</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {usersList &&
                    usersList.map((user, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <>
                              <h5 className="text-capitalize">{user.name}</h5>
                              <div className="small text-muted">{user.phone}</div>
                              <div className="small text-muted">{user.email}</div>
                            </>
                          </td>
                          <td className="text-center">
                            {user?.settings?.app?.platform}
                            {user?.settings?.app?.version?.length > 0 && <div className="small text-muted">{`v${user?.settings?.app?.version}`}</div>}
                          </td>
                          <td className="text-center">
                            {user?.settings?.driver_location_permission?.level.length > 0 && (
                              <>
                                <div className="text-muted">{`${user?.settings?.driver_location_permission?.mode === true ? 'Turned On' : 'Turned Off'}`}</div>

                                {user?.settings?.driver_location_permission?.mode === true && <div className={user?.settings?.driver_location_permission?.level === 'enabled' ? 'badge badge-success text-capitalize' : 'badge badge-danger text-capitalize'}>{`${user?.settings?.driver_location_permission?.level}`} </div>}
                              </>
                            )}
                          </td>
                          <td className="text-center">
                            {user.title}
                            <div className="small text-muted">{user.company}</div>
                          </td>
                          <td className="text-center">
                            {user.roles.carrier[carrierId] === 'viewer' && <span className="badge badge-primary">Viewer</span>}
                            {user.roles.carrier[carrierId] === 'editor' && <span className="badge badge-warning">Editor</span>}
                            {user.roles.carrier[carrierId] === 'owner' && <span className="badge badge-danger">Owner</span>}
                          </td>
                          <td>
                            {user?.license_proofs.length > 0 && (
                              <Button size="sm" disabled={loading} variant="outline-info" onClick={() => toggleLicenseModal(user)} className="ml-2">
                                License
                              </Button>
                            )}
                          </td>
                          <td className="text-right">
                            <div className="dropdown">
                              <a href="/#" className="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fe fe-more-vertical"></i>
                              </a>
                              <div className="dropdown-menu dropdown-menu-right">
                                <button
                                  className="dropdown-item"
                                  onClick={() => {
                                    editUserModal(user);
                                  }}
                                >
                                  Edit
                                </button>
                                <button
                                  className="dropdown-item"
                                  onClick={() => {
                                    handleInvite(user);
                                  }}
                                >
                                  Resend Invite
                                </button>
                                <button
                                  className="dropdown-item"
                                  onClick={() => {
                                    showResetPasswordModal(user);
                                  }}
                                >
                                  Reset Password
                                </button>
                                <button
                                  className="dropdown-item"
                                  onClick={() => {
                                    handleRemove(user);
                                  }}
                                >
                                  Remove
                                </button>
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </Spinner>
          </Card>
        </Container>

        <Modal show={showInviteModal} onHide={hideInviteModal}>
          <Modal.Body>
            <Modal.Title className="h1">Invite User</Modal.Title>
            <hr />

            <Formik initialValues={inviteUser} validationSchema={inviteUserSchema} onSubmit={handleInviteUser}>
              {({ handleChange, handleSubmit, values, errors }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Form.Group>
                    <Form.Label>
                      Name <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control type="text" name="name" placeholder="e.g. John Doe" value={values.name} onChange={handleChange} isInvalid={!!errors.name} />
                    <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" name="email" placeholder="e.g. john@phoxhealth.com" value={values.email} onChange={handleChange} />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>
                      Phone <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control type="phone" name="phone" maxLength="10" placeholder="e.g. +1 983 331 3464" value={values.phone} onChange={handleChange} isInvalid={!!errors.phone} />
                    <Form.Control.Feedback type="invalid">{errors.phone}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Title</Form.Label>
                    <Form.Control type="text" name="title" placeholder="e.g. Pharmacist" value={values.title} onChange={handleChange} isInvalid={!!errors.title} />
                    <Form.Control.Feedback type="invalid">{errors.title}</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>Role</Form.Label>
                    <Form.Control as="select" name="role" custom value={values.role} onChange={handleChange}>
                      <option value="viewer">Viewer</option>
                      <option value="editor">Editor</option>
                      <option value="owner">Owner</option>
                    </Form.Control>
                  </Form.Group>

                  <Button type="submit" variant="primary">
                    Invite User
                  </Button>
                  <Button variant="outline-secondary" onClick={hideInviteModal} className="ml-2">
                    Cancel
                  </Button>
                </Form>
              )}
            </Formik>
          </Modal.Body>
        </Modal>

        <Modal show={showEditUserModal} onHide={hideEditUserModal}>
          <Modal.Body>
            <Modal.Title className="h1">Edit User</Modal.Title>
            <hr />
            <Formik initialValues={user} validationSchema={editUserSchema} onSubmit={handleEditUser}>
              {({ handleChange, handleSubmit, values, errors }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Form.Group>
                    <Form.Label>
                      Name <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control type="text" name="name" placeholder="e.g. John Doe" value={values.name} onChange={handleChange} isInvalid={!!errors.name} />
                    <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>
                      Phone <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control disabled={user?.phone ? true : false} type="phone" name="phone" maxLength="10" placeholder="e.g. +1 983 331 3464" value={values.phone} onChange={handleChange} isInvalid={!!errors.phone} />
                    <Form.Control.Feedback type="invalid">{errors.phone}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" name="email" placeholder="e.g. john@phoxhealth.com" value={values.email} onChange={handleChange} />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Title</Form.Label>
                    <Form.Control type="text" name="title" placeholder="e.g. Pharmacist" value={values.title} onChange={handleChange} isInvalid={!!errors.title} />
                    <Form.Control.Feedback type="invalid">{errors.title}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Role</Form.Label>
                    <Form.Control as="select" name="role" custom value={values.role} onChange={handleChange}>
                      <option value="viewer">Viewer</option>
                      <option value="editor">Editor</option>
                      <option value="owner">Owner</option>
                    </Form.Control>
                  </Form.Group>
                  <Button type="submit" variant="primary">
                    Update
                  </Button>
                  <Button variant="outline-secondary" onClick={hideEditUserModal} className="ml-2">
                    Cancel
                  </Button>
                </Form>
              )}
            </Formik>
          </Modal.Body>
        </Modal>

        <Modal
          show={modalEditUser}
          onHide={() => {
            toggleEditUserModal(false);
          }}
        >
          <Modal.Body>
            <Modal.Title className="h1">Reset Password</Modal.Title>
            <hr />

            <Formik initialValues={user} validationSchema={inviteUserSchema} onSubmit={handleResetPassword}>
              {({ handleChange, handleSubmit, values, errors }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Form.Group>
                    <Form.Control type="text" name="password" value={values.password} onChange={handleChange} isInvalid={!!errors.password} />
                    <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                  </Form.Group>

                  <Button type="submit" variant="primary">
                    Update
                  </Button>
                  <Button
                    variant="outline-secondary"
                    onClick={() => {
                      toggleEditUserModal(false);
                    }}
                    className="ml-2"
                  >
                    Cancel
                  </Button>
                </Form>
              )}
            </Formik>
          </Modal.Body>
        </Modal>

        <Modal show={showProofModal} onHide={() => setShowProofModal(false)}>
          <Modal.Body>
            <Modal.Title>
              <Row>
                <Col xs={6}>
                  <h1>License ID Proof</h1>
                </Col>
                <Col xs={6} className="text-right">
                  <Button variant="outline-secondary" onClick={() => setShowProofModal(false)}>
                    Close
                  </Button>
                </Col>
              </Row>
            </Modal.Title>
            <hr />
            <ImageGallery items={user.license_proofs} showBullets={false} showThumbnails={false} lazyLoad={true} showPlayButton={false} />
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return { user: state.user };
};
export default connect(mapStateToProps)(CarrierUsers);
