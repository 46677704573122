import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { updateToken } from '../../Utils';
import Navbar from '../../components/Navigation';
import Header from '../../components/Header';
import LabTypeSetting from './LabTypeSetting';
import Spinner from '../../components/Spinner';
import GeneralSettings from './GeneralSettings';
import TimeSlotSetting from './TimeSlotSetting';
import StorageTypeSetting from './StorageTypeSetting';
import ServiceTypeSetting from './ServiceTypeSetting';
import { getShippersById } from '../../services/index';

const ShipperSettings = (props) => {

  const shipperId = props.match.params.id;
  const [active, setActive] = useState('general');
  const [shipper, setShipper] = useState({});
  const [loading, showSpinner] = useState(false);

  useEffect(() => {
    getShipper();
    document.title = 'Settings';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getShipper = async () => {
    showSpinner(true);
    const response = await getShippersById(shipperId);
    if (response) {
      if (response.status === 200 || response.status === 201) {
        setShipper(response?.data);
        showSpinner(false);
      } else {
        if (response.response.status === 401 || response.response.data === 'Unauthorized') {
          await updateToken();
        }
        if (response.response.status >= 500) {
          toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
        }
        showSpinner(false);
      }
    }
  };

  return (
    <>
      <Navbar />
      <div className='main-content'>
        <Header
          title={shipper.name}
          name={
            <>
              <ul className='nav nav-tabs'>
                <li className='nav-item'>
                  <div className={active === 'general' ? 'nav-link active' : 'nav-link'} style={{ cursor: 'pointer' }} onClick={() => setActive('general')}>
                    <h1 className='header-title'>General</h1>
                  </div>
                </li>
                <li className='nav-item'>
                  <div className={active === 'time-slot' ? 'nav-link active' : 'nav-link'} style={{ cursor: 'pointer' }} onClick={() => setActive('time-slot')}>
                    <h1 className='header-title'>Time-Slot</h1>
                  </div>
                </li>
                <li className='nav-item'>
                  <div className={active === 'storage' ? 'nav-link active' : 'nav-link'} style={{ cursor: 'pointer' }} onClick={() => setActive('storage')}>
                    <h1 className='header-title'>Storage Type</h1>
                  </div>
                </li>
                <li className='nav-item'>
                  <div className={active === 'service' ? 'nav-link active' : 'nav-link'} style={{ cursor: 'pointer' }} onClick={() => setActive('service')}>
                    <h1 className='header-title'>Service Type</h1>
                  </div>
                </li>
                <li className='nav-item'>
                  <div className={active === 'product' ? 'nav-link active' : 'nav-link'} style={{ cursor: 'pointer' }} onClick={() => setActive('product')}>
                    <h1 className='header-title'>Product Type</h1>
                  </div>
                </li>
              </ul>
            </>
          }
        ></Header>
        <Spinner display={loading}>{active === 'time-slot' ? <TimeSlotSetting shipperId={shipperId} /> : active === 'service' ? <ServiceTypeSetting shipperId={shipperId} /> : active === 'storage' ? <StorageTypeSetting shipperId={shipperId} /> : active === 'product' ? <LabTypeSetting shipperId={shipperId} /> : <GeneralSettings shipperId={shipperId} />}</Spinner>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return { user: state.user };
};
export default connect(mapStateToProps)(ShipperSettings);
