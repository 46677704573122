import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import axios from 'axios';
import * as msal from '@azure/msal-browser';

import Spinner from '../../components/Spinner';
import { auth_msal } from '../../services/auth';
import { setUserId, trackPageView } from '../../helpers/Analytics';

toast.configure();
const Admin = 'owner';

const Login = (props) => {
  const tenant = props.tenant_config;
  const [loading, showSpinner] = useState(false);

  useEffect(() => {
    document.title = `Login`;
    trackPageView();
  }, []);

  const doAzureLogin = async () => {
    try {
      showSpinner(true);
      const instance = new msal.PublicClientApplication({
        auth: tenant.msalConfig,
        cache: {
          cacheLocation: 'sessionStorage',
          storeAuthStateInCookie: false,
        },
      });
      const msalResponse = await instance.loginPopup({
        redirectUri: tenant?.msal?.redirectUri,
      });
      const account = msalResponse.account;
      const loginResponse = await auth_msal(account);
      if (loginResponse?.status === 200) {
        await doPostLogin(loginResponse?.data);
        showSpinner(false);
      } else {
        showSpinner(false);
        toast.error('Invalid login');
      }
    } catch (error) {
      console.log(error);
      showSpinner(false);
    }
  };

  const doPostLogin = async (user) => {
    localStorage.setItem('ph-token-console', user?.token);
    //Special permissions
    const permissions = user.roles.system === 'owner' ? 'super-admin' : user.roles.system
    localStorage.setItem('ph-special-permission', permissions);
    axios.defaults.headers.common['Authorization'] = `Bearer ${user?.token}`;
    setUserId(user.email);
    setTimeout(() => {
      props.history.push('/dashboard/delivery-performance');
    }, 3000);
    if (user.roles['system'] === Admin) {
      await props.dispatch({ type: 'USER', payload: user });
      setTimeout(() => {
        props.history.push('/dashboard/delivery-performance');
      }, 3000);
    } else {
      showSpinner(false);
      toast.error('Admin access denied.');
    }
  };

  const { tenant_logo, tenant_icon } = props;

  return (
    <div className="d-flex align-items-center bg-auth border-top border-top-2 border-primary vh-100">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-12 col-md-5 col-lg-6 col-xl-4 px-lg-6 my-5 align-self-center">
            <div className="text-center">
              <img src={tenant_logo} alt="logo" className="img-fluid" />
            </div>
            <h1 className="display-4 text-center mt-4 mb-0">Sign In</h1>
            <p className="text-muted text-center mt-2">
              Admin Console
              {process.env.REACT_APP_ENV !== 'PROD' && <span> ({process.env.REACT_APP_ENV})</span>}
            </p>
            <Spinner display={loading}>
              {tenant?.msalEnabled === true && (
                <button className="btn btn-lg w-100 btn-white" onClick={doAzureLogin}>
                  <img src={tenant_icon} alt={tenant.name || 'icon'} height="20px" className="mx-2" />
                  Continue with {tenant?.name}
                </button>
              )}
            </Spinner>
          </div>
          <div className="col-12 col-md-7 col-lg-6 col-xl-8 d-none d-lg-block">
            <div className="bg-cover h-100 min-vh-100 mt-n1 me-n3" style={{ backgroundImage: "url('/img/login-cover1.png')" }}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    tenant_logo: state.tenant_logo,
    tenant_config: state.tenant_config,
    tenant_icon: state.tenant_icon,
  };
};

export default connect(mapStateToProps)(Login);
