/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import Navbar from '../../components/Navigation';
import Header from '../../components/Header';

import { toTimeZone, getDateTimeDiffernce } from '../../Utils';
import { collection, onSnapshot, where, query, doc, deleteDoc } from 'firebase/firestore';
import { db } from '../../firebaseConfig';

const Today = (props) => {

  const [shipments, setShipments] = useState([]);

  useEffect(() => {
    console.log('end', moment().endOf('day').toDate());
    onSnapshot(
      query(collection(db, 'shipments'),
        where('status', 'in', ['Ready for Pickup', 'Out for Delivery']),
        where('estimated_delivery_date', '<=', moment().endOf('day').toDate())
      ),
      async (dbShipments) => {
        let myShipments = [];

        dbShipments.forEach((dbShipment) => {
          myShipments.push(dbShipment.data());
        });

        myShipments.sort(function (a, b) { return a.route_duration - b.route_duration; });
        setShipments(myShipments);
      }
    );
  }, []);

  const handleDelete = async (shipment) => {
    await deleteDoc(doc(db, 'shipments', shipment.id));
  }

  return (
    <>
      <Navbar />
      <div className='main-content'>
        <Header title='Phox Health' name={`Today (${shipments.length})`}></Header>
        <div className='container-fluid mt-5'>
          <div className='card d-flex flex-row align-items-center justify-content-start'>
            <table className='table table-sm table-striped table-hover'>
              <thead>
                <tr>
                  <th className="text-center">Shipment#</th>
                  <th>Sender</th>
                  <th>Recipient</th>
                  <th>Driver</th>
                  <th className='text-center'>Due By</th>
                  <th className='text-center'>ETA</th>
                  <th className='text-center'>Status</th>
                  <th className='d-none'></th>
                </tr>
              </thead>
              <tbody>
                {
                  shipments.map((shipment, index) => {
                    return (
                      <tr key={index} style={{ cursor: 'pointer' }} onClick={() => window.open(`/shipment/${shipment.id}`, "_blank")}>
                        <td className='text-center'>
                          {shipment.number}
                          <div className='small text-muted'>{shipment.service_type}</div>
                        </td>
                        <td>
                          {shipment.ship_from.shipper_name}
                          <div className='small text-muted'>{shipment.ship_from.city}, {shipment.ship_from.state}</div>
                        </td>
                        <td>
                          {shipment.ship_to.contact_name}
                          <div className='small text-muted'>{shipment.ship_to.city}, {shipment.ship_to.state}</div>
                        </td>
                        <td>
                          {shipment.driver?.name}
                          <div className='small text-muted'>{shipment.carrier?.name}</div>
                        </td>
                        <td className='text-center'>
                          {
                            shipment.estimated_delivery_date &&
                            <div>
                              {getDateTimeDiffernce(new Date(shipment.estimated_delivery_date.seconds * 1000), toTimeZone(moment(), 'lll', shipment.ship_from.timezone))}
                            </div>
                          }
                        </td>
                        <td className='text-center'>
                          {Math.round(shipment.route_duration / 60)} mins
                          <div className='small text-muted'>{Math.round(shipment.route_distance * 0.000621371)} mi</div>
                        </td>
                        <td className='text-center'>
                          {shipment.status === 'Info Received' && <span className="h3 text-primary mr-2">●</span>}
                          {shipment.status === 'Processing' && <span className="h3 text-primary mr-2">●</span>}
                          {shipment.status === 'Ready for Pickup' && <span className="h3 text-primary mr-2">●</span>}
                          {shipment.status === 'Out for Delivery' && <span className="h3 text-warning mr-2">●</span>}
                          {shipment.status === 'Delivered' && <span className="h3 text-success mr-2">●</span>}
                          {shipment.status === 'Exception' && <span className="h3 text-danger mr-2">●</span>}
                          {shipment.status === 'Out for Return' && (
                            <span className="h3 mr-2" style={{ color: 'rgb(251,129,55)' }}>
                              ●
                            </span>
                          )}
                          {shipment.status === 'Returned' && (
                            <span className="h3 mr-2" style={{ color: 'rgb(136,90,234)' }}>
                              ●
                            </span>
                          )}
                          {shipment.status === 'Exception' ? 'Cancelled' : shipment.status}
                        </td>
                        <td className='text-right d-none'>
                          <span className='dropdown'>
                            <a href='/#' className='dropdown-ellipses dropdown-toggle' role='button' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                              <i className='fe fe-more-vertical'></i>
                            </a>
                            <div className='dropdown-menu dropdown-menu-right'>
                              <button className='dropdown-item' onClick={() => { handleDelete(shipment); }}>Delete</button>
                            </div>
                          </span>
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    shipper: state.shipper,
    shippers: state.shippers,
  };
};

export default connect(mapStateToProps)(Today);