/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Dropdown from 'react-bootstrap/Dropdown';
import { mapStyle } from '../../constants/index';
import { Map, Marker, InfoWindow, GoogleApiWrapper } from 'google-maps-react';

import { collection, onSnapshot, where, query } from 'firebase/firestore';
import { db } from '../../firebaseConfig';

const DriverTracker = (props) => {

  const [shipments, setShipments] = useState([]);
  const [activeMarker, setMarker] = useState({});

  const [bounds, setBounds] = useState([]);

  const [drivers, setDrivers] = useState([]);
  const [driver, setDriver] = useState({});
  const [driverFilter, setDriverFilter] = useState('');

  const [selectedPlace, setSelectedPlace] = useState({});
  const [showingToInfoWindow, setToInfoWindow] = useState(false);
  const [showingFromInfoWindow, setFromInfoWindow] = useState(false);
  const [showingDriverInfoWindow, setDriverInfoWindow] = useState(false);

  useEffect(() => {
    onSnapshot(
      query(collection(db, 'shipments'), where('status', '==', 'Out for Delivery')),
      (dbShipments) => {
        let myDrivers = [];
        dbShipments.forEach((dbShipment) => {
          let shipment = dbShipment.data();
          if (myDrivers.filter(x => x.id === shipment.driver_id).length === 0)
            if (shipment.driver) {
              myDrivers.push({
                id: shipment.driver.id,
                name: shipment.driver.name
              });
            }
        });

        setDrivers(myDrivers);
      }
    );

    getDriverData();

  }, [driverFilter]);

  useEffect(() => {
    let myBounds = new props.google.maps.LatLngBounds();

    shipments.forEach((shipment) => {
      myBounds.extend({ lat: shipment.ship_to.location._latitude, lng: shipment.ship_to.location._longitude })
    })

    if (driver.latitude && driver.longitude) {
      myBounds.extend({ lat: driver.latitude, lng: driver.longitude })
    }

    setBounds(myBounds);
  }, [shipments, driver]);

  const getDriverData = async () => {
    onSnapshot(
      query(collection(db, 'shipments'), where('status', '==', 'Out for Delivery'), where('driver_id', '==', driverFilter)),
      async (dbShipments) => {
        let myShipments = [];

        dbShipments.forEach((dbShipment) => {
          let shipment = dbShipment.data();
          myShipments.push(shipment);
        });

        myShipments.sort(function (a, b) {
          return a.route_sequence - b.route_sequence;
        });

        console.log('shipments', myShipments.length)
        setShipments(myShipments);
      }
    );

    onSnapshot(
      query(collection(db, 'driver_v2'), where('id', '==', driverFilter)),
      (dbDrivers) => {
        dbDrivers.forEach(async (dbDriver) => {
          let myDriver = dbDriver.data();
          console.log('driver', myDriver.id, myDriver.name);
          setDriver(myDriver);
        });
      }
    );
  }

  const onMarkerClick = async (propData, marker, place) => {
    setMarker(marker);
    if (propData && place) {
      setSelectedPlace(place);
    }
    if (propData.name === 'shipmentTo') {
      setFromInfoWindow(false);
      setDriverInfoWindow(false);
      setToInfoWindow(true);
    }
    if (propData.name === 'driver') {
      setFromInfoWindow(false);
      setToInfoWindow(false);
      setDriverInfoWindow(true);
    }
  };

  const onMapClicked = () => {
    if (showingFromInfoWindow) {
      setMarker(null);
      setFromInfoWindow(false);
    }
    if (showingToInfoWindow) {
      setMarker(null);
      setToInfoWindow(false);
    }
    if (showingDriverInfoWindow) {
      setMarker(null);
      setDriverInfoWindow(false);
    }
  };

  return (
    <div>
      <div className="fixed-top vh-100">
        <Map styles={props.mapStyles} google={props.google} zoom={12} containerStyle={mapStyle} mapTypeControl={false} streetViewControl={false} onClick={onMapClicked} bounds={bounds}>
          {
            shipments.map((shipment, index) => {
              return (
                <Marker key={index} id={index} name="shipmentTo" className="labels"
                  icon={{ url: `http://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=${shipment.route_sequence}|f6c343|000000` }}
                  position={{
                    lat: shipment.ship_to && Number(shipment.ship_to.location?._latitude),
                    lng: shipment.ship_to && Number(shipment.ship_to.location?._longitude),
                  }}
                  onClick={(propData, marker) => onMarkerClick(propData, marker, shipment)}
                />
              );
            })
          }
          <InfoWindow marker={activeMarker} visible={showingToInfoWindow}>
            <div>
              {
                selectedPlace.ship_to && (
                  <div className="mt-3 text-dark">
                    <div>{selectedPlace.ship_to.company_name}</div>
                    <div>{selectedPlace.ship_to.contact_name}</div>
                    <div className="text-muted mt-2">
                      <div>{selectedPlace.ship_to.address1}</div>
                      <div>{selectedPlace.ship_to.address2}</div>
                      <div>{selectedPlace.ship_to.address3}</div>
                      <div>
                        {selectedPlace.ship_to.city}, {selectedPlace.ship_to.state} {selectedPlace.ship_to.postal_code}
                      </div>
                    </div>
                    <a href={`/shipment/${selectedPlace.id}`}>
                      <div>Learn More</div>
                    </a>
                  </div>
                )
              }
            </div>
          </InfoWindow>
          {
            driver && <Marker name="driver" className="labels" icon={{ url: `http://chart.apis.google.com/chart?chst=d_map_pin_icon&chld=taxi|edf2f9|ffffff` }}
              position={{ lat: driver.latitude, lng: driver.longitude, }}
            />
          }
        </Map>
      </div>
      <div className="fixed-top w-25 m-4 d-none d-lg-block">
        <Dropdown>
          <Dropdown.Toggle variant="white">Driver{driverFilter && <span>: {drivers.filter(x => x.id === driverFilter)[0].name}</span>}</Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => { setDriverFilter(''); }}>All</Dropdown.Item>
            {
              drivers && drivers.map((driver, i) => {
                return (
                  <Dropdown.Item key={i} onClick={() => { setDriverFilter(driver.id); }}>
                    {driver.name}
                  </Dropdown.Item>
                );
              })
            }
          </Dropdown.Menu>
        </Dropdown>
        {shipments.length > 0 &&
          <div className='card mt-3'>
            <div className='card-body'>
              <div className='list-group list-group-flush my-n3'>
                {
                  shipments.map((shipment, i) => {
                    return (
                      <div className='list-group-item' key={i}>
                        <div className='row align-items-center'>
                          <div className='col-auto text-muted'>
                            <span class="badge bg-primary-soft">{shipment.route_sequence}</span>
                          </div>
                          <div className='col'>{shipment.ship_to.contact_name}</div>
                          <div className='col-auto text-muted'>
                            {Math.round(shipment.route_duration / 60)} mins
                          </div>
                          <div className='col-auto text-muted'>
                            {Math.round(shipment.route_distance * 0.000621371)} mi
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    shipper: state.shipper,
    shippers: state.shippers,
  };
};

DriverTracker.defaultProps = {
  mapStyles: [
    {
      featureType: 'administrative',
      elementType: 'geometry',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'transit',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'all',
      stylers: [
        {
          saturation: 0,
        },
        {
          hue: '#e7ecf0',
        },
      ],
    },
    {
      featureType: 'road',
      stylers: [
        {
          saturation: -70,
        },
      ],
    },
    {
      featureType: 'transit',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'water',
      stylers: [
        {
          visibility: 'simplified',
        },
        {
          saturation: -60,
        },
      ],
    },
  ],
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  libraries: ['places', 'visualization'],
})(connect(mapStateToProps)(DriverTracker));
