/* eslint-disable react-hooks/exhaustive-deps */
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Login from './pages/auth/Login.js';
import ForgotPassword from './pages/auth/ForgotPassword';
import ResetPassword from './pages/auth/ResetPassword';

import PerformanceDashboard from './pages/dashboard/PermforamceDashboard';
import TrackingDashboard from './pages/dashboard/TrackingDashboard';
import NPSDashboard from './pages/dashboard/NPSDashboard';
import DriverTracker from './pages/dashboard/DriverTracker.js';
import Today from './pages/dashboard/Today';

import Shipments from './pages/shipments/Shipments';
import TodaysDeliveries from './pages/shipments/TodaysDeliveries';
import ShipmentDetail from './pages/shipments/ShipmentDetail';

import ShipperList from './pages/shipper/ShipperList';
import AddEditShipper from './pages/shipper/AddEditShipper';
import ShipperDashboard from './pages/shipper/ShipperDashboard';
import ShipperUsers from './pages/shipper/ShipperUsers';
import ShipperSettings from './pages/shipper/ShipperSettings';

import CarrierList from './pages/carrier/CarrierList';
import AddEditCarrier from './pages/carrier/AddEditCarrier';
import CarrierUsers from './pages/carrier/CarrierUsers';
import CarrierSettings from './pages/carrier/CarrierSettings';
import CarrierDashboard from './pages/carrier/CarrierDashboard';

import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';

import { ref, getDownloadURL } from 'firebase/storage';
import { getRemoteConfig, getValue, fetchAndActivate } from 'firebase/remote-config';
import { storage } from './firebaseConfig';


const App = (props) => {

  const remoteConfig = getRemoteConfig();
  remoteConfig.settings.minimumFetchIntervalMillis = 60;

  useEffect(() => {
    loadInitialData();
  }, []);

  const loadInitialData = () => {
    fetchAndActivate(remoteConfig)
      .then(() => {
        //shipment status list
        let statusList = getValue(remoteConfig, 'shipment_status');
        statusList = Object.values(JSON.parse(statusList?._value));
        props.dispatch({ type: 'SHIPMENT_STATUSLIST', payload: statusList });

        //shipment instruction list
        let instructionList = getValue(remoteConfig, 'delivery_instructions');
        instructionList = Object.values(JSON.parse(instructionList?._value));
        props.dispatch({ type: 'DELIVERY_INSTRUCTIONS', payload: instructionList });

        //Tracking mapStyles
        let mapStyle = getValue(remoteConfig, 'tracking_mapstyle');
        mapStyle = JSON.parse(mapStyle?._value);
        props.dispatch({ type: 'MAP_STYLE', payload: mapStyle });

        // Tenant
        const host = window.location.hostname.toLowerCase();
        let hosts = getValue(remoteConfig, 'hosts');
        hosts = JSON.parse(hosts?._value);
        const tenant = hosts.hasOwnProperty(host) ? hosts[host] : 'default';
        // const tenant = window.location.hostname.toLowerCase();
        props.dispatch({ type: 'TENANT', payload: tenant });

        //Tenant Logo
        getDownloadURL(ref(storage, `/img/${tenant}/logo.png`)).then((url) => {
          props.dispatch({ type: 'TENANT_LOGO', payload: url });
        });

        getDownloadURL(ref(storage, `/img/${tenant}/icon.png`)).then((url) => {
          document.getElementById('favicon').href = url;
          props.dispatch({ type: 'TENANT_ICON', payload: url });
        });

        // Tenant Config
        let tenant_config = getValue(remoteConfig, `tenant_config_${tenant}`);
        tenant_config = JSON.parse(tenant_config?._value);
        props.dispatch({ type: 'TENANT_CONFIG', payload: tenant_config });
      })
      .catch((err) => {
        console.log('remote config error', err);
      });
  };

  const { user } = props;
  const isAllowed = isEmpty(user);
  return (
    <div>
      <Router>
        <Switch>
          <Route exact path='/' component={Login} />
          <Route exact path='/forgot-password' component={ForgotPassword} />
          <Route exact path='/reset-password/:token' component={ResetPassword} />
          {!isAllowed && <Route exact path='/dashboard/delivery-performance' component={PerformanceDashboard} />}
          {!isAllowed && <Route exact path='/dashboard/live-tracking' component={TrackingDashboard} />}
          {!isAllowed && <Route exact path='/dashboard/driver' component={DriverTracker} />}
          {!isAllowed && <Route exact path='/dashboard/nps' component={NPSDashboard} />}
          {!isAllowed && <Route exact path='/dashboard/today' component={Today} />}
          {!isAllowed && <Route exact path='/todays-deliveries' component={TodaysDeliveries} />}
          {!isAllowed && <Route exact path='/shipment' component={Shipments} />}
          {!isAllowed && <Route exact path='/shipment/:id' component={ShipmentDetail} />}
          {!isAllowed && <Route exact path='/shipper' component={ShipperList} />}
          {!isAllowed && <Route exact path='/shipper/create' component={AddEditShipper} />}
          {!isAllowed && <Route exact path='/shipper/:id/edit' component={AddEditShipper} />}
          {!isAllowed && <Route exact path='/shipper/:id/dashboard' component={ShipperDashboard} />}
          {!isAllowed && <Route exact path='/shipper/:id/users' component={ShipperUsers} />}
          {!isAllowed && <Route exact path='/shipper/:id/settings' component={ShipperSettings} />}
          {!isAllowed && <Route exact path='/carrier' component={CarrierList} />}
          {!isAllowed && <Route exact path='/carrier/create' component={AddEditCarrier} />}
          {!isAllowed && <Route exact path='/carrier/:id/edit' component={AddEditCarrier} />}
          {!isAllowed && <Route exact path='/carrier/:id/dashboard' component={CarrierDashboard} />}
          {!isAllowed && <Route exact path='/carrier/:id/users' component={CarrierUsers} />}
          {!isAllowed && <Route exact path='/carrier/:id/settings' component={CarrierSettings} />}
        </Switch>
      </Router>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    tenant: state.tenant,
    tenant_config: state.tenant_config,
    tenant_logo: state.tenant_logo,
    tenant_icon: state.tenant_icon,
    shipment_statuslist: state.shipment_statuslist,
    delivery_instruction: state.delivery_instruction,
    tracking_mapstyle: state.tracking_mapstyle,
  };
};
export default connect(mapStateToProps)(App);
