import { createStore } from 'redux';

const initial_state = {
  tenant: '',
  tenant_logo: '',
  tenant_icon: '',
  tenant_config: '',
  user: {},
  shippers: [],
  shipper: {},
  shipment_statuslist: {},
  delivery_instruction: {},
  tracking_mapstyle: {},
};

const appReducer = (state = initial_state, action) => {
  switch (action.type) {
    case 'TENANT':
      return { ...state, tenant: action.payload };
    case 'TENANT_LOGO':
      return { ...state, tenant_logo: action.payload };
    case 'TENANT_ICON':
      return { ...state, tenant_icon: action.payload };
    case 'TENANT_CONFIG':
      return { ...state, tenant_config: action.payload };
    case 'USER':
      return { ...state, user: action.payload };
    case 'SHIPMENT_STATUSLIST':
      return { ...state, shipment_statuslist: action.payload };
    case 'DELIVERY_INSTRUCTIONS':
      return { ...state, delivery_instruction: action.payload };
    case 'MAP_STYLE':
      return { ...state, tracking_mapstyle: action.payload };
    default:
      return state;
  }
};

const loadState = () => {
  try {
    const serializedState = localStorage.getItem('ph-console');
    if (!serializedState) return undefined;
    else return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('ph-console', serializedState);
  } catch (err) {
    console.log(err);
  }
};

const store = createStore(appReducer, loadState(), window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

store.subscribe(() => {
  saveState(store.getState());
});

export default store;
