import React from 'react';
import Card from 'react-bootstrap/Card'

class CardTitle extends React.Component {
    render() {
        return (
            <Card.Header>
                <h4 className='card-header-title'>{this.props.title}</h4>
                {this.props.children}
            </Card.Header>
        );
    }
}

export default CardTitle;