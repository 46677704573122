import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { updateToken } from '../../Utils';
import Navbar from '../../components/Navigation';
import Header from '../../components/Header';
import Spinner from '../../components/Spinner';
import TimeSlotSetting from './TimeSlotSetting';
import GeneralSettings from './GeneralSettings';
import { getCarrierById } from '../../services/index';

const CarrierSettings = (props) => {
  const carrierId = props.match.params.id;

  const [carrier, setCarrier] = useState({});
  const [active, setActive] = useState(false);
  const [loading, showSpinner] = useState(false);

  useEffect(() => {
    getCarrier();
    document.title = 'Settings';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCarrier = async () => {
    showSpinner(true);
    const response = await getCarrierById(carrierId);
    if (response) {
      if (response.status === 200 || response.status === 201) {
        setCarrier(response?.data);
        showSpinner(false);
      } else {
        if (response.response.status === 401 || response.response.data === 'Unauthorized') {
          await updateToken();
        }
        if (response.response.status >= 500) {
          toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
        }
        showSpinner(false);
      }
    }
  };

  return (
    <>
      <Navbar />
      <div className='main-content'>
        <Header
          title={carrier.name}
          name={
            <>
              <ul className='nav nav-tabs'>
                <li className='nav-item'>
                  <div className={active ? 'nav-link' : 'nav-link active'} style={{ cursor: 'pointer' }} onClick={() => setActive(false)}>
                    <h1 className='header-title'>General</h1>
                  </div>
                </li>
                <li className='nav-item'>
                  <div className={active ? 'nav-link active' : 'nav-link'} style={{ cursor: 'pointer' }} onClick={() => setActive(true)}>
                    <h1 className='header-title'>Time-Slot</h1>
                  </div>
                </li>
              </ul>
            </>
          }
        ></Header>
        <Spinner display={loading}>{!active ? <GeneralSettings carrierId={carrierId} /> : <TimeSlotSetting carrierId={carrierId} />}</Spinner>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return { user: state.user };
};
export default connect(mapStateToProps)(CarrierSettings);
