import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { updateToken } from '../../Utils';
import Header from '../../components/Header';
import Navbar from '../../components/Navigation';
import Spinner from '../../components/Spinner';
import { getCarrierById } from '../../services/index';

const CarrierDashboard = (props) => {
  const carrierId = props.match.params.id;
  const [carrier, setCarrier] = useState({});
  const [loading, showSpinner] = useState(false);

  useEffect(() => {
    showSpinner(true);
    getCarrier();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCarrier = async () => {
    const response = await getCarrierById(carrierId);
    if (response) {
      if (response.status === 200 || response.status === 201) {
        setCarrier(response?.data);
        showSpinner(false);
      } else {
        if (response.response.status === 401 || response.response?.data === 'Unauthorized') {
          await updateToken();
        }
        if (response.response.status >= 500) {
          toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
        }
        showSpinner(false);
      }
    }
  };

  return (
    <>
      <Navbar />
      <div className="main-content">
        <Spinner display={loading}>
          <Header title='Courier' name={carrier.name}>
            <Link to={`/carrier/${carrierId}/users`} className='btn btn-white ml-1'>
              Users
            </Link>
            <Link to={`/carrier/${carrierId}/settings`} className='btn btn-white ml-1'>
              Settings
            </Link>
          </Header>
        </Spinner>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return { user: state.user };
};
export default connect(mapStateToProps)(CarrierDashboard);
