export const trackPageView = () => {
    window.gtag('config', process.env.REACT_APP_GOOGLE_ANALYTICS_CODE);
    window.gtag('send', 'page_view', {
        page_location: window.location.href,
        page_path: window.location.pathname
    });
}

export const setUserId = (email) => {
    window.gtag('config', 'GA_MEASUREMENT_ID', {
        'user_id': email
    });
}

export const trackEvent = (category, action, label) => {

};

export const trackException = (error, fatal) => {

}

export const trackPurchase = (id, amount) => {
    // ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_CODE);
}