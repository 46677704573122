import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Spinner from '../../components/Spinner';
import Container from 'react-bootstrap/Container';
import { forgotPassword } from '../../services/auth';
import { trackPageView } from '../../helpers/Analytics';

const ForgotPassword = (props) => {

  const initialValues = {
    email: '',
  };

  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string().email('Email is invalid.').required('Email is required.'),
  });

  const [loading, showSpinner] = useState(false);

  useEffect(() => {
    document.title = `Forgot Password`;
    trackPageView();
  }, []);


  const sendPasswordResetEmail = async (event) => {
    const { email } = event;
    showSpinner(true);
    try {
      const request = { email: email.toLowerCase().trim(), source: 'admin' };
      const response = await forgotPassword(request);
      if (response) {
        if (response.status === 200 || response.status === 201) {
          toast.info(`An email as been sent to ${email} with instructions to reset your password.`);
          showSpinner(false);
          props.history.push('/');
        } else {
          showSpinner(false);
          toast.warn(`An error occured while trying to process your request. Please try again or contact support@phoxhealth.com`);
        }
      }
    } catch (error) {
      console.log(error);
      toast.warn(`An error occured while trying to process your request. Please try again or contact support@phoxhealth.com`);
      showSpinner(false);
    }
  };

  return (
    <>
      <div className='d-flex align-items-center bg-auth border-top border-top-2 border-primary vh-100' style={{ display: 'block' }}>
        <Container>
          <Row className='justify-content-center'>
            <Col className='col-12 col-md-5 col-xl-4 my-5'>
              <div className='text-center'>
                <Image src='/img/logo.png' alt='...' className='img-fluid mb-5' />
                <h1 className='display-4 mb-3'>Forgot Password?</h1>
                <p className='text-muted  mb-5'>Enter your email to get password reset instructions</p>
              </div>
              <Spinner display={loading}>
                <Formik initialValues={initialValues} validationSchema={forgotPasswordSchema} onSubmit={sendPasswordResetEmail}>
                  {({ handleChange, handleSubmit, values, errors }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                      <Form.Group>
                        <Form.Control type='email' name='email' placeholder='email@phoxhealth.com' value={values.email} onChange={handleChange} isInvalid={!!errors.email} />
                        <Form.Control.Feedback type='invalid'>{errors.email}</Form.Control.Feedback>
                      </Form.Group>
                      <Button type='submit' className='btn btn-lg btn-block btn-primary mb-3 btn-login'>
                        Continue
                      </Button>
                      <div className='text-center text-muted small'>
                        Remember your password? <Link to='/'>Sign In</Link>
                      </div>
                    </Form>
                  )}
                </Formik>
              </Spinner>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ForgotPassword;
