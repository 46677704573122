import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import moment from 'moment';
import swal from 'sweetalert';
import { Formik } from 'formik';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Spinner from '../../components/Spinner';
import Container from 'react-bootstrap/Container';
import { updateToken, toLocalDate } from '../../Utils';
import { getDefaultCarrierTimeSlot, getCarrierTimeSlotById, deleteCarrierTimeSlotSettingById, addCarrierTimeSlotSetting, updateCarrierTimeSlotSettingById } from '../../services/index';

const TimeSlotSetting = (props) => {
  
  const settingSchema = Yup.object().shape({
    start_time: Yup.string().required('Start Time is required.'),
    end_time: Yup.string().required('End time is required.'),
  });

  const timeSlotSetting = {
    start_time: '',
    end_time: '',
  };

  const carrierId = props.carrierId;
  const [loading, showSpinner] = useState(false);
  const [timeSetting, setTimeSetting] = useState({});
  const [message, setMessage] = useState('');
  const [modalTimeSlot, showTimeSlotModal] = useState(false);
  const [showAddSettingModal, setShowAddSettingModal] = useState(false);
  const [showChangeSettingModal, setShowChangeSettingModal] = useState(false);
  const [deliveryTimeSlot, setDeliveryTimeSlot] = useState([]);
  const [defaultTimeSlot, setDefaultTimeSlot] = useState([]);

  useEffect(() => {
    getDeliveryTimeSlot();
    getDefaultTimeSlot();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDeliveryTimeSlot = async () => {
    try {
      const response = await getCarrierTimeSlotById(carrierId);
      if (response) {
        if (response.status === 200 || response.status === 201) {
          setDeliveryTimeSlot(response?.data);
        } else {
          if (response?.response?.status === 401 || response?.response?.data === 'Unauthorized') {
            await updateToken();
          }
          if (response?.response?.status >= 500) {
            toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDefaultTimeSlot = async () => {
    try {
      const response = await getDefaultCarrierTimeSlot();
      if (response) {
        if (response.status === 200 || response.status === 201) {
          if (response?.data) {
            setDefaultTimeSlot(response?.data);
          }
        } else {
          if (response?.response?.status === 401 || response?.response?.data === 'Unauthorized') {
            await updateToken();
          }
          if (response?.response?.status >= 500) {
            toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addSettingModal = () => {
    setShowAddSettingModal(true);
  };

  const hideAddSettingModal = () => {
    setShowAddSettingModal(false);
  };

  const changeSettingModal = (timeSetting) => {
    setTimeSetting(timeSetting);
    setShowChangeSettingModal(true);
  };

  const hideChangeSettingModal = () => {
    setShowChangeSettingModal(false);
  };

  const handleRemove = async (setting) => {
    swal({
      title: `Remove ${setting.name} TimeSlot`,
      text: `Are you sure that you want to remove ${setting.name} TimeSlot?`,
      buttons: ['Cancel', 'Yes'],
      icon: 'error',
      dangerMode: true,
    }).then(async (status) => {
      if (status) {
        showSpinner(true);
        const response = await deleteCarrierTimeSlotSettingById(carrierId, setting.id);
        if (response) {
          if (response.status === 200 || response.status === 201) {
            getDeliveryTimeSlot();
            showSpinner(false);
            toast.success(`${setting.name} TimeSlot has been removed.`);
          } else {
            if (response.response.status === 401 || response.response.data === 'Unauthorized') {
              await updateToken();
            }
            if (response.response.status >= 500) {
              toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
            }
            showSpinner(false);
          }
        }
      }
    });
  };

  const handleSubmit = async (data, isEdit) => {
    let valid = true;
    if (data.start_time > data.end_time) {
      valid = false;
      setMessage('Start Time must be always less than End Time');
    } else if (data.start_time === data.end_time) {
      valid = false;
      setMessage('Start Time and End Time cannot be same');
    }
    if (valid) {
      setMessage('');
      Submit(data, isEdit);
    }
  };

  const Submit = async (data, isEdit) => {
    const custom_setting = {
      name: `${moment(data.start_time, 'HH:mm').format('ha')} - ${moment(data.end_time, 'HH:mm').format('ha')}`,
      start_time: data.start_time,
      end_time: data.end_time,
    };
    showSpinner(true);
    const response = isEdit ? await updateCarrierTimeSlotSettingById(carrierId, data.id, custom_setting) : await addCarrierTimeSlotSetting(carrierId, custom_setting);
    if (response) {
      if (response.status === 200 || response.status === 201) {
        hideAddSettingModal();
        hideChangeSettingModal();
        getDeliveryTimeSlot();
        toast.success(`${data.name} TimeSlot has been updated.`);
        showSpinner(false);
      } else {
        if (response?.response?.status === 409) {
          toast.error(response.response.data.error);
        }
        if (response.response.status === 401 || response.response.data === 'Unauthorized') {
          await updateToken();
        }
        if (response.response.status >= 500) {
          toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
        }
        showSpinner(false);
      }
    }
  };

  return (
    <>
      <Container>
        <Card>
          <Card.Header>
            <Row>
              <Col></Col>
              <Col sm='auto' className='p-0 mr-3 text-right'>
                <Button variant='white' onClick={() => showTimeSlotModal(true)}>
                  Default Setting
                </Button>
              </Col>
              <Col sm='auto' className='p-0 text-right'>
                <Button variant='white' onClick={addSettingModal}>
                  Add Setting
                </Button>
              </Col>
            </Row>
          </Card.Header>
          <Spinner display={loading}>
            <Table responsive size='sm'>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Created Date</th>
                  <th>Modified Date</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {deliveryTimeSlot &&
                  deliveryTimeSlot.map((setting, index) => {
                    return (
                      <tr key={index}>
                        <td>{setting.name}</td>
                        <td>
                          {toLocalDate(setting.create_date, 'LT')}
                          <div className='small text-muted'>{toLocalDate(setting.create_date, 'll')}</div>
                        </td>
                        <td>
                          {toLocalDate(setting.modify_date, 'LT')}
                          <div className='small text-muted'>{toLocalDate(setting.modify_date, 'll')}</div>
                        </td>
                        <td className='text-right'>
                          <div className='dropdown'>
                            <a href='/#' className='dropdown-ellipses dropdown-toggle' role='button' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                              <i className='fe fe-more-vertical'></i>
                            </a>
                            <div className='dropdown-menu dropdown-menu-right'>
                              <button
                                className='dropdown-item'
                                onClick={() => {
                                  changeSettingModal(setting);
                                }}
                              >
                                Change
                              </button>
                              <button
                                className='dropdown-item'
                                onClick={() => {
                                  handleRemove(setting);
                                }}
                              >
                                Remove
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </Spinner>
        </Card>
      </Container>
      <Modal show={showAddSettingModal} onHide={hideAddSettingModal}>
        <Modal.Body>
          <Modal.Title className='h1'>Add Setting</Modal.Title>
          <hr />
          <Formik initialValues={timeSlotSetting} validationSchema={settingSchema} onSubmit={(data) => handleSubmit(data, false)}>
            {({ handleChange, handleSubmit, values, errors }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>
                        Start Time<span className='text-danger'>*</span>
                      </Form.Label>
                      <Form.Control type='time' onKeyDown={(e) => e.preventDefault()} name='start_time' max='00:00' min='00:00' value={values.start_time} onChange={handleChange} isInvalid={!!errors.start_time} />
                      <Form.Control.Feedback type='invalid'>{errors.start_time}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>
                        End Time<span className='text-danger'>*</span>
                      </Form.Label>
                      <Form.Control type='time' onKeyDown={(e) => e.preventDefault()} name='end_time' max='00:00' min='00:00' value={values.end_time} onChange={handleChange} isInvalid={!!errors.end_time} />
                      <Form.Control.Feedback type='invalid'>{errors.end_time}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                {(values.start_time > values.end_time || values.start_time === values.end_time) && <span className='text-danger mb-2'>{message}</span>}
                <br />
                <Button className='mt-3' type='submit' variant='primary'>
                  Add Setting
                </Button>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
      <Modal show={showChangeSettingModal} onHide={hideChangeSettingModal}>
        <Modal.Body>
          <Modal.Title className='h1 mb-0'>{timeSetting.name}</Modal.Title>
          <hr />
          <Formik initialValues={timeSetting} validationSchema={settingSchema} onSubmit={(data) => handleSubmit(data, true)}>
            {({ handleChange, handleSubmit, values, errors }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>
                        Start Time<span className='text-danger'>*</span>
                      </Form.Label>
                      <input type='time' onKeyDown={(e) => e.preventDefault()} className='form-control' name='start_time' value={values.start_time} onChange={handleChange} isInvalid={!!errors.start_time} />
                      <Form.Control.Feedback type='invalid'>{errors.start_time}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>
                        End Time<span className='text-danger'>*</span>
                      </Form.Label>
                      <input type='time' onKeyDown={(e) => e.preventDefault()} className='form-control' name='end_time' value={values.end_time} onChange={handleChange} isInvalid={!!errors.end_time} />
                      <Form.Control.Feedback type='invalid'>{errors.end_time}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                {(values.start_time > values.end_time || values.start_time === values.end_time) && <span className='text-danger mb-2'>{message}</span>}
                <br />
                <Button className='mt-3' type='submit' variant='primary'>
                  Add Setting
                </Button>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
      <Modal show={modalTimeSlot} onHide={() => showTimeSlotModal(false)}>
        <Modal.Body>
          <Modal.Title className='h1'>Default Time-Slot</Modal.Title>
          <hr />
          <Row>
            {defaultTimeSlot.map((data) => {
              return (
                <Col xs={6}>
                  <Form.Group>
                    <Form.Control type='text' name='text' value={data.name} readOnly />
                  </Form.Group>
                </Col>
              );
            })}
          </Row>
          <hr />
          <Button
            variant='secondary'
            onClick={() => {
              showTimeSlotModal(false);
            }}
            className='ml-2'
          >
            Close
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return { user: state.user };
};
export default connect(mapStateToProps)(TimeSlotSetting);
