import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import swal from 'sweetalert';
import { Formik } from 'formik';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import { updateToken } from '../../Utils';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Spinner from '../../components/Spinner';
import Container from 'react-bootstrap/Container';
import { addCarrierSetting, getCarrierSettingsById, getDefaultSetting, deleteSettingById, updateSettingById } from '../../services/index';

const settingSchema = Yup.object().shape({
  value: Yup.string().required('Value is required.'),
});

const GeneralSettings=(props)=> {

  const carrierId = props.carrierId;
  const [loading, showSpinner] = useState(false);
  const [carrierSetting, setCarrierSetting] = useState({});
  const [defaultSettings, setDefaultSettings] = useState([]);
  const [carrierSettings, setCarrierSettings] = useState([]);
  const [showAddSettingModal, setShowAddSettingModal] = useState(false);
  const [showChangeSettingModal, setShowChangeSettingModal] = useState(false);

  useEffect(() => {
    getCarrierSettings();
    getDefaultSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCarrierSettings = async () => {
    showSpinner(true);
    const response = await getCarrierSettingsById(carrierId);
    if (response) {
      if (response.status === 200 || response.status === 201) {
        setCarrierSettings(response?.data);
        showSpinner(false);
      } else {
        if (response.response.status === 401 || response.response.data === 'Unauthorized') {
          await updateToken();
        }
        if (response.response.status >= 500) {
          toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
        }
        showSpinner(false);
      }
    }
  };

  const getDefaultSettings = async () => {
    showSpinner(true);
    const response = await getDefaultSetting();
    if (response) {
      if (response.status === 200 || response.status === 201) {
        setDefaultSettings(response?.data);
        showSpinner(false);
      } else {
        if (response.response.status === 401 || response.response.data === 'Unauthorized') {
          await updateToken();
        }
        if (response.response.status >= 500) {
          toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
        }
        showSpinner(false);
      }
    }
  };

  const addSettingModal = () => {
    setShowAddSettingModal(true);
  };

  const hideAddSettingModal = () => {
    setShowAddSettingModal(false);
  };

  const changeSettingModal = (carrierSetting) => {
    setCarrierSetting(carrierSetting);
    setShowChangeSettingModal(true);
  };

  const hideChangeSettingModal = () => {
    setShowChangeSettingModal(false);
  };

  const handleRemove = async (setting) => {
    swal({
      title: `Remove ${setting.name}`,
      text: `Are you sure that you want to remove ${setting.name}?`,
      buttons: ['Cancel', 'Yes'],
      icon: 'error',
      dangerMode: true,
    }).then(async (status) => {
      if (status) {
        showSpinner(true);
        const response = await deleteSettingById(carrierId, setting.id);
        if (response) {
          if (response.status === 200 || response.status === 201) {
            getCarrierSettings();
            toast.success(`${setting.name} has been removed.`);
          } else {
            if (response.response.status === 401 || response.response.data === 'Unauthorized') {
              await updateToken();
            }
            if (response.response.status >= 500) {
              toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
            }
            showSpinner(false);
          }
        }
      }
    });
  };

  const handleSubmit = async (data, isEdit) => {
    hideAddSettingModal();
    hideChangeSettingModal();
    const setting = defaultSettings.filter((x) => x.id === data.id)[0];
    const custom_setting = {};
    if (isEdit) {
      custom_setting.value = data.value;
      custom_setting.key = data.key;
      custom_setting.name = data.name;
    } else {
      custom_setting.key = setting.key;
      custom_setting.name = setting.name;
      custom_setting.value = data.value;
    }
    showSpinner(true);
    const response = isEdit ? await updateSettingById(carrierId, data.id, custom_setting) : await addCarrierSetting(carrierId, custom_setting);
    if (response) {
      if (response.status === 200 || response.status === 201) {
        getCarrierSettings();
        toast.success(`${setting.name} has been updated.`);
        showSpinner(false);
      } else {
        if (response.response.status === 401 || response.response.data === 'Unauthorized') {
          await updateToken();
        }
        if (response.response.status >= 500) {
          toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
        }
        showSpinner(false);
      }
    }
  };

  return (
    <>
      <Container>
        <Card>
          <Card.Header>
            <Row>
              <Col></Col>
              <Col sm='auto' className='p-0 text-right'>
                <Button variant='white' onClick={addSettingModal}>
                  Add Setting
                </Button>
              </Col>
            </Row>
          </Card.Header>
          <Spinner display={loading}>
            <Table responsive size='sm'>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Value</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {carrierSettings &&
                  carrierSettings.map((setting, index) => {
                    return (
                      <tr key={index}>
                        <td>{setting.name}</td>
                        <td>{setting.value}</td>
                        <td className='text-right'>
                          <div className='dropdown'>
                            <a href='/#' className='dropdown-ellipses dropdown-toggle' role='button' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                              <i className='fe fe-more-vertical'></i>
                            </a>
                            <div className='dropdown-menu dropdown-menu-right'>
                              <button
                                className='dropdown-item'
                                onClick={() => {
                                  changeSettingModal(setting);
                                }}
                              >
                                Change
                              </button>
                              <button
                                className='dropdown-item'
                                onClick={() => {
                                  handleRemove(setting);
                                }}
                              >
                                Remove
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </Spinner>
        </Card>
      </Container>

      <Modal show={showAddSettingModal} onHide={hideAddSettingModal}>
        <Modal.Body>
          <Modal.Title className='h1'>Add Setting</Modal.Title>
          <hr />

          <Formik initialValues={carrierSetting} validationSchema={settingSchema} onSubmit={(data) => handleSubmit(data, false)}>
            {({ handleChange, handleSubmit, values, errors }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group>
                  <Form.Control as='select' name='id' custom value={values.id} onChange={handleChange}>
                    <option value='-'>Select Setting</option>
                    {defaultSettings &&
                      defaultSettings.map((setting, index) => {
                        return (
                          <option key={index} value={setting.id}>
                            {setting.name}
                          </option>
                        );
                      })}
                  </Form.Control>
                </Form.Group>

                <Form.Group>
                  <Form.Control as='textarea' rows='5' name='value' value={values.value} onChange={handleChange} isInvalid={!!errors.value} />
                  <Form.Control.Feedback type='invalid'>{errors.value}</Form.Control.Feedback>
                </Form.Group>

                <Button type='submit' variant='primary'>
                  Add Setting
                </Button>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>

      <Modal show={showChangeSettingModal} onHide={hideChangeSettingModal}>
        <Modal.Body>
          <Modal.Title className='h1 mb-0'>{carrierSetting.name}</Modal.Title>
          <hr />

          <Formik initialValues={carrierSetting} validationSchema={settingSchema} onSubmit={(data) => handleSubmit(data, true)}>
            {({ handleChange, handleSubmit, values, errors }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group>
                  <Form.Control as='textarea' rows='5' name='value' value={values.value} onChange={handleChange} isInvalid={!!errors.value} />
                  <Form.Control.Feedback type='invalid'>{errors.value}</Form.Control.Feedback>
                </Form.Group>

                <Button type='submit' variant='primary'>
                  Update
                </Button>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
  return { user: state.user };
};
export default connect(mapStateToProps)(GeneralSettings);
